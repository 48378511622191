import UIActionTypes from './actions-types';

const INITIAL_STATE = {
    paymentMethodModalVisible: false,
    emailConfirmationModalVisible: false,
    editPersonModalVisible: false,
    hraModalVisible: false,
    successModalVisible: false,
    logoutMessage: null,
    logoutMessageColour: '#8246AF',
    terminatedModalVisible: false,
    referAFriendModalVisible: false,
    claimPaymentsModalVisible: false,
    benefitCalculatorModalVisible: false,
    rebateFormModalVisible: false,
    claimDisclaimerModalVisible: false,
    onlineClaimServicesVisible: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UIActionTypes.SET_PAYMENT_METHOD_MODAL_VISIBLE:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(
                    `SET_PAYMENT_METHOD_MODAL_VISIBLE with payload ${action.payload}`,
                );
            }
            return { ...state, paymentMethodModalVisible: action.payload };

        case UIActionTypes.SET_EMAIL_CONFIRMATION_MODAL_VISIBLE:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(
                    `SET_EMAIL_CONFIRMATION_MODAL_VISIBLE with payload ${action.payload}`,
                );
            }
            return { ...state, emailConfirmationModalVisible: action.payload };

        case UIActionTypes.SET_EDIT_PERSON_MODAL_VISIBLE:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(
                    `SET_EDIT_PERSON_MODAL_VISIBLE with payload ${action.payload}`,
                );
            }
            return { ...state, editPersonModalVisible: action.payload };

        case UIActionTypes.SET_HRA_MODAL_VISIBLE:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(
                    `SET_HRA_MODAL_VISIBLE with payload ${action.payload}`,
                );
            }
            return { ...state, hraModalVisible: action.payload };

        case UIActionTypes.SET_SUCCESS_MODAL_VISIBLE:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(
                    `SET_SUCCESS_MODAL_VISIBLE with payload ${action.payload}`,
                );
            }
            return { ...state, successModalVisible: action.payload };

        case UIActionTypes.SET_LOGOUT_MESSAGE:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(
                    `SET_LOGOUT_MESSAGE with payload ${action.payload}`,
                );
            }
            return { ...state, logoutMessage: action.payload };

        case UIActionTypes.SET_LOGOUT_MESSAGE_COLOUR:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(
                    `SET_LOGOUT_MESSAGE_COLOUR with payload ${action.payload}`,
                );
            }
            return { ...state, logoutMessageColour: action.payload };

        case UIActionTypes.SET_TERMINATED_MODAL_VISIBLE:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(
                    `SET_TERMINATED_MODAL_VISIBLE with payload ${action.payload}`,
                );
            }
            return { ...state, terminatedModalVisible: action.payload };

        case UIActionTypes.SET_REFERAFRIEND_MODAL_VISIBLE:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(
                    `SET_REFERAFRIEND_MODAL_VISIBLE with payload ${action.payload}`,
                );
            }
            return { ...state, referAFriendModalVisible: action.payload };

        case UIActionTypes.SET_CLAIMPAYMENT_MODAL_VISIBLE:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(
                    `SET_CLAIMPAYMENT_MODAL_VISIBLE with payload ${action.payload}`,
                );
            }
            return { ...state, claimPaymentsModalVisible: action.payload };

        case UIActionTypes.SET_BENEFITCALCULATOR_MODAL_VISIBLE:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(
                    `SET_CLAIMPAYMENT_MODAL_VISIBLE with payload ${action.payload}`,
                );
            }
            return { ...state, benefitCalculatorModalVisible: action.payload };

        case UIActionTypes.SET_REBATEFORM_MODAL_VISIBLE:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(
                    `SET_REBATEFORM_MODAL_VISIBLE with payload ${action.payload}`,
                );
            }
            return { ...state, rebateFormModalVisible: action.payload };

        case UIActionTypes.SET_CLAIMDISCLAIMER_MODAL_VISIBLE:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(
                    `SET_CLAIMDISCLAIMER_MODAL_VISIBLE with payload ${action.payload}`,
                );
            }
            return { ...state, claimDisclaimerModalVisible: action.payload };
        case UIActionTypes.SET_ONLINECLAIMSERVICES_MODAL_VISIBLE:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(
                    `SET_ONLINECLAIMSERVICES_MODAL_VISIBLE with payload ${action.payload}`,
                );
            }
            return { ...state, onlineClaimServicesVisible: action.payload };

        default:
            return state;
    }
};
