const gtagEvent = (event) => {
    if (!event) return console.log('INVALID_GTAG_EVENT: event not specified.');

    const { screen, action, label, type } = event;
    const trackingId = process.env.REACT_APP_GTAG_ID;

    if (!window.gtag)
        return console.log('Could not get "window.gtag", gtagEvent not fired.');
    if (!trackingId)
        return console.log('Tracking not enabled, gtagEvent not fired.');
    if (!screen || typeof screen !== 'string')
        return console.log('INVALID_GTAG_EVENT: "screen" is invalid.');
    if (!action || typeof action !== 'string')
        return console.log('INVALID_GTAG_EVENT: "action" is invalid.');
    if (!label || typeof label !== 'string')
        return console.log('INVALID_GTAG_EVENT: "label" is invalid.');

    let append = '';
    if (!type) append = '_screen';
    if (type === 'modal') append = '_modal';
    if (type === 'alert') append = '_alert';
    if (type === 'header') append = '';

    const eventName = `${screen}_${action}`;
    const eventCategory = `${screen}${append}`;

    if (type === 'modal_open') {
        console.log('FIRE MODALVIEW', {
            page_path: `/modal/${action}`,
        });
        window.gtag('event', 'page_view', {
            page_location: `/modal/${action}`,
            page_path: `/modal/${action}`,
            page_title: `/modal/${action}`,
        });
        window.gtag('event', `modal_${action}`, {
            event_category: eventCategory,
            event_label: label,
        });
    } else {
        window.gtag('event', eventName, {
            event_category: eventCategory,
            event_label: label,
        });
    }
};

export default gtagEvent;
