import moment from 'moment';
import { FastClaimTypeItem } from 'types/fastClaim';

export const disableAddItemButton = (item: FastClaimTypeItem | undefined, feeCorrect: boolean) => {
    const hasFee = !item?.itemFee;
    const feeNotEmpty = item?.itemFee === '';
    const noBodyPartRequired = item?.item?.IsBodyPartRequired === null;
    const requiredBodyPartNotEmpty =
        !noBodyPartRequired &&
        item?.item?.IsBodyPartRequired &&
        item?.itemBodyPart === '';
    const noItem = !item?.item;

    return (
        hasFee ||
        feeNotEmpty ||
        noBodyPartRequired ||
        requiredBodyPartNotEmpty ||
        !feeCorrect ||
        noItem
    );
};

export const detectDuplicateItem = (currentItem: FastClaimTypeItem | undefined, claimItems: FastClaimTypeItem[]) => {
    return claimItems.some((el) => isDuplicate(el, currentItem));
};

const isDuplicate = (target: FastClaimTypeItem, compare?: FastClaimTypeItem) => {
    const id = compare?.id === target?.id;
    const date = compare?.dateOfService === target?.dateOfService;
    const provider = compare?.provider?.providerid === target?.provider?.providerid;
    const item = compare?.item?.Description === target?.item?.Description;
    const person = compare?.personId === target?.personId;
    const itemBodyPart = target?.itemBodyPart === compare?.itemBodyPart;
    return date && provider && item && person && itemBodyPart && !id;
};

export const injectService = (item, benefitService) => {
    const description = benefitService.Description;
    const typeCode = benefitService?.SpecialityClassCode;
    const highRisk = benefitService?.HighRisk === true;
    const forceDefault = benefitService?.ForceDefaultProviderOnClaim === true;

    let providerId;
    if (forceDefault) providerId = benefitService.DefaultProvider;

    return {
        ...item,
        Description: description,
        serviceTypeCode: typeCode,
        tempproviderid: providerId,
        provider: { providerid: providerId },
        item: null,
        itemFee: '',
        itemBodyPart: '',
        highRisk: highRisk,
        forceDefaultProviderOnClaim: forceDefault,
    };
};

export const injectDate = (item, event) => {
    return {
        ...item,
        dateOfServiceControl: event,
        dateOfService: moment(event).format('YYYY-MM-DD'),
    };
};

export const injectClaimant = (item, event) => {
    const emptyPerson = {
        ...item,
        personId: event.target.value,
        serviceTypeCode: '',
        tempproviderid: '',
        provider: null,
        item: null,
        itemFee: '',
        itemBodyPart: '',
        Description: '',
    };
    const selectedPerson = {
        ...item,
        personId: event.target.value,
    };

    return event.target.value === '' ? emptyPerson : selectedPerson;
};

export const injectProvider = (item, provider) => {
    const previousProvider = {
        ...item,
        provider: provider,
        tempproviderid: provider?.providerid,
    };
    const unspecifiedProvider = {
        ...item,
        provider: null,
        tempproviderid: '',
    };

    return !provider ? unspecifiedProvider : previousProvider;
};

export const resetClaimItems = (item) => ({
    ...item,
    item: null,
    itemFee: '',
    itemBodyPart: '',
});

export const handleProviderSearch = (item, providerID) => {
    const providerFound = {
        ...item,
        tempproviderid: providerID,
        item: null,
        itemFee: '',
        itemBodyPart: '',
    };

    const emptyString = {
        ...item,
        tempproviderid: '',
        provider: null,
        item: null,
        itemFee: '',
        itemBodyPart: '',
    };

    return providerID !== '' ? providerFound : emptyString;
};

export const injectServiceItem = (item, serviceItem) => {
    const hasItem = {
        ...item,
        item: serviceItem,
    };
    const noItem = {
        ...item,
        item: null,
    };
    return !serviceItem ? noItem : hasItem;
};

export const confirmProvider = (response, providerID) => {
    return response.length === 1 && response[0].providerid === providerID;
};

export const disableProviderSearch = (item) => {
    return item?.serviceTypeCode === undefined || item?.serviceTypeCode === '';
};

export const disableItemSelect = (item, services) => {
    return item?.provider?.providerid === undefined || services.length === 0;
};

export const getMedicareMessage = (service, pbsAmount) => {
    return service?.MedicareMessage
        ? service?.MedicareMessage.replace(
              '|PBS_AMOUNT|',
              parseFloat(pbsAmount).toFixed(2),
          )
        : service?.MedicareMessage;
};
