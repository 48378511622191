import { getAccessToken } from 'libs/auth';
import { CONFIG } from 'libs/constants';
import gtagEvent from 'libs/gtagEvent';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { fastClaimActions, setClaimPaymentsModalVisible } from 'store';
import api from '@qldtuh/tuh-uh-js-api';

const PaymentDetails = () => {
    const membership = useSelector((state) => state.membership);
    const fastClaimStore = useSelector((state) => state.fastClaim);
    const dispatch = useDispatch();
    const [bankName, setBankName] = useState('');
    const [bsbValid, setBsbValid] = useState(false);
    const [accountValid, setAccountValid] = useState(false);
    const [nameValid, setNameValid] = useState(false);

    function changeSection() {
        dispatch(fastClaimActions.general.setStep('2'));
    }

    const [checkBSBState, checkBSB] = useAsyncFn(async (value) => {
        setBsbValid(false);
        if (value.match(/(?:\d{3})(?:-)?(?:\d{3})/)) {
            const access_token = getAccessToken();
            try {
                const response = await api.app(CONFIG, access_token).bsb(value);

                if (response?.status == 'error') {
                    throw new Error(response);
                } else {
                    console.log(response);
                    setBankName('Bank: ' + response.Bank);
                    setBsbValid(true);
                }
            } catch (err) {
                console.log('useAsyncFn is probably not being used correctly', {
                    checkBSBState,
                });
                console.log('ERROR');
                console.log(err);
                setBankName('Invalid Bank');
                setBsbValid(false);
            }
        }
    });

    return (
        <>
            <div className="p-4 payment-details-section">
                <div className="d-flex">
                    <div>
                        <div className="pl-3">
                            <div>
                                {
                                    membership?.CreditAccount
                                        ?.BankAccount?.Bank
                                }
                            </div>
                            <div>
                                BSB{' '}
                                {
                                    membership?.CreditAccount
                                        ?.BankAccount?.BsbNumber
                                }
                            </div>
                            <div>
                                Account{' '}
                                {membership?.CreditAccount?.BankAccount?.AccountNumber.replace(
                                    /\d(?=\d{4})/g,
                                    '*',
                                )}
                            </div>
                            <div>
                                <Button
                                    variant="link"
                                    style={{
                                        padding: '0px',
                                    }}
                                    className="text-primary-dark-1"
                                    onClick={() => {
                                        gtagEvent({
                                            screen: 'fastclaim',
                                            action: 'claim_payments',
                                            label: 'changed payment in step two of fast claim',
                                            type: 'modal_open',
                                        });
                                        dispatch(
                                            setClaimPaymentsModalVisible(true),
                                        );
                                    }}>
                                    Change
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="pl-5 d-none">
                        <Form.Check
                            inline
                            type="radio"
                            id={`benefit-oneoff`}
                            label={`Use a different, one-time Bank Account`}
                            name={`bankaccount`}
                            className={'text-label-gray font-weight-medium'}
                            style={{
                                fontSize: '13px',
                            }}
                            checked={
                                fastClaimStore.useOneTimeBankAccount === true
                            }
                            onChange={() => {
                                dispatch(
                                    fastClaimActions.oneTimeBankAccount.set.use(
                                        true,
                                    ),
                                );
                            }}
                        />
                        <div
                            className="pl-3"
                            style={{
                                display:
                                    fastClaimStore.useOneTimeBankAccount ===
                                    true
                                        ? 'flex'
                                        : 'none',
                            }}>
                            <Form.Group
                                controlId={`benefit-bsb`}
                                className="d-inline-block text-label-gray">
                                <Form.Label style={{ fontSize: '11px' }}>
                                    BSB
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={
                                        fastClaimStore.oneTimeBankAccountDetails
                                            .bsb
                                    }
                                    onChange={(val) => {
                                        setBankName('');
                                        dispatch(
                                            fastClaimActions.oneTimeBankAccount.set.details(
                                                {
                                                    ...fastClaimStore.oneTimeBankAccountDetails,
                                                    bsb: val.target.value,
                                                },
                                            ),
                                        );
                                        checkBSB(val.target.value);
                                    }}
                                />
                                <div
                                    className="pl-1"
                                    style={{ fontSize: '12px' }}>
                                    {bankName}
                                </div>
                            </Form.Group>
                            <Form.Group
                                controlId={`benefit-account`}
                                className="d-inline-block text-label-gray">
                                <Form.Label style={{ fontSize: '11px' }}>
                                    Account Number
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={
                                        fastClaimStore.oneTimeBankAccountDetails
                                            .acc
                                    }
                                    onChange={(val) => {
                                        if (
                                            !isNaN(val.target.value) &&
                                            val.target.value.trim() !== ''
                                        ) {
                                            dispatch(
                                                fastClaimActions.oneTimeBankAccount.set.details(
                                                    {
                                                        ...fastClaimStore.oneTimeBankAccountDetails,
                                                        acc: val.target.value,
                                                    },
                                                ),
                                            );
                                            setAccountValid(true);
                                        } else if (!isNaN(val.target.value)) {
                                            dispatch(
                                                fastClaimActions.oneTimeBankAccount.set.details(
                                                    {
                                                        ...fastClaimStore.oneTimeBankAccountDetails,
                                                        acc: val.target.value,
                                                    },
                                                ),
                                            );
                                            setAccountValid(false);
                                        } else {
                                            setAccountValid(false);
                                        }
                                    }}
                                />
                            </Form.Group>
                        </div>
                        <div
                            className="pl-3"
                            style={{
                                display:
                                    fastClaimStore.useOneTimeBankAccount ===
                                    true
                                        ? 'flex'
                                        : 'none',
                            }}>
                            <Form.Group
                                controlId={`benefit-holder`}
                                className="w-100 text-label-gray">
                                <Form.Label style={{ fontSize: '11px' }}>
                                    Account Holder
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={
                                        fastClaimStore.oneTimeBankAccountDetails
                                            .name
                                    }
                                    onChange={(val) => {
                                        dispatch(
                                            fastClaimActions.oneTimeBankAccount.set.details(
                                                {
                                                    ...fastClaimStore.oneTimeBankAccountDetails,
                                                    name: val.target.value,
                                                },
                                            ),
                                        );
                                        setNameValid(true);
                                    }}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row-reverse">
                    <Button
                        variant="secondary"
                        className=""
                        onClick={() => {
                            gtagEvent({
                                screen: 'fastclaim',
                                action: 'two_next',
                                label: 'Clicked next button in step two of fast claim',
                            });
                            changeSection();
                        }}
                        disabled={
                            (fastClaimStore.useOneTimeBankAccount === true &&
                                !bsbValid) ||
                            (fastClaimStore.useOneTimeBankAccount === true &&
                                !accountValid) ||
                            (fastClaimStore.useOneTimeBankAccount === true &&
                                !nameValid)
                        }>
                        Next
                    </Button>
                </div>
            </div>
        </>
    );
};

export default PaymentDetails;
