import React, { Fragment } from 'react';
import { Form } from 'react-bootstrap';
import { injectProvider } from '../common/functions';
import { FastClaimTypeItem } from 'types/fastClaim';
import { ProviderType } from '@qldtuh/tuh-uh-js-api';

interface Props {
    lastUsedProviders: ProviderType[];
    claimItem?: FastClaimTypeItem;
    updateClaim: (newObject: any) => void;
    handleChange: (event: React.ChangeEvent<any>) => void;
}

export const LastUsedProviders: React.FC<Props> = ({
    lastUsedProviders,
    claimItem,
    updateClaim,
    handleChange,
}) => {
    return (
        <Form.Group
            controlId={`last-providers`}
            className="text-label-gray"
            style={{
                display:
                    claimItem?.forceDefaultProviderOnClaim === true ||
                    lastUsedProviders?.length === 0
                        ? 'none'
                        : 'block',
            }}>
            <Form.Label>Last Used Providers</Form.Label>
            <Form.Control
                as="select"
                disabled={!claimItem?.Description}
                onChange={(event) => {
                    const provider = lastUsedProviders.find(
                        (lastProvider) =>
                            lastProvider.providerid === event.target.value,
                    );
                    const newObject = injectProvider(claimItem, provider);
                    updateClaim(newObject);
                    handleChange(event);
                }}>
                <option value=""></option>
                {lastUsedProviders &&
                    lastUsedProviders.map((item, idx) => {
                        if (
                            item.SpecialityClassCode ===
                            claimItem?.serviceTypeCode
                        ) {
                            return (
                                <option key={idx} value={item.providerid}>
                                    {item.providerid} - {item.title}{' '}
                                    {item.initials} {item.surname}
                                </option>
                            );
                        }
                        return <Fragment key={idx}></Fragment>;
                    })}
            </Form.Control>
        </Form.Group>
    );
};

export default LastUsedProviders;
