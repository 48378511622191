import React, { useEffect, useState } from 'react';
import { thunkLogin, thunkGetSettings } from 'store';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { CONFIG } from 'libs/constants';
import api from '@qldtuh/tuh-uh-js-api';
import Fade from 'react-reveal/Fade';
import gtagEvent from 'libs/gtagEvent';
import HeadShake from 'react-reveal/HeadShake';
import ReactMarkdown from 'react-markdown';
import LogoPrimaryPositive from 'img/vector/LogoPrimaryPositive/comp';
import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';
import {
    DownloadButton,
    FeatureImage,
    FeatureTitle,
    Terms,
} from '../common/components/exports';
import { StoreType } from 'store/types';

const AuthenticationScreen: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [featureImage, setFeatureImage] = useState<JSX.Element>();
    const [featureTitle, setFeatureTitle] = useState<JSX.Element>();
    const [androidButton, setAndroidButton] = useState<JSX.Element>();
    const [iosButton, setIosButton] = useState<JSX.Element>();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [outcome, setOutcome] = useState<string | null>(null);

    const settings = useSelector((state: StoreType) => state.settings);

    const logoutMessage = useSelector((state: StoreType) => state.ui.logoutMessage);
    const logoutMessageColour = useSelector((state: StoreType) => state.ui.logoutMessageColour);

    const history = useHistory();
    const dispatch = useDispatch();

    const [devMessage, setDevMessage] = useState('');

    const loadFeature = ({ portal: { login: response } }) => {
        setFeatureTitle(
            <FeatureTitle
                text={<ReactMarkdown>{response.text}</ReactMarkdown>}
            />,
        );

        setFeatureImage(<FeatureImage src={response.bg_img} />);

        setIosButton(
            <DownloadButton
                image={response.app_store_img}
                link={response.app_store_url}
                alt={'iOS App Store download button'}
                gtag={() => {
                    gtagEvent({
                        screen: 'login',
                        action: 'download_ios',
                        label: 'Download from AppStore',
                    });
                }}
            />,
        );

        setAndroidButton(
            <DownloadButton
                image={response.play_store_img}
                link={response.play_store_url}
                alt={'Google Play Store download button'}
                gtag={() => {
                    gtagEvent({
                        screen: 'login',
                        action: 'download_android',
                        label: 'Download from Google Play Store',
                    });
                }}
            />,
        );
    };

    useEffect(() => {
        dispatch(thunkGetSettings());

        if (process.env.REACT_APP_API_ENVIRONMENT !== 'prod') {
            setDevMessage('THIS IS A TEST SYSTEM. CLAIMS WILL NOT BE PAID');
        }
    }, []);

    useEffect(() => {
        if (settings?.portal) {
            loadFeature(settings);
        }
    }, [settings]);

    const onSubmit = (event) => {
        gtagEvent({
            screen: 'login',
            action: 'login',
            label: 'Login Button Clicked',
        });

        setLoading(true);
        event.preventDefault();

        api.user(CONFIG)
            .login({ username, password })
            .then((response) => {
                setLoading(false);

                if (response.status === 'success') {
                    dispatch(thunkLogin(response));
                    history.push('/member');
                } else {
                    setOutcome('fail');
                    setMessage(
                        response.message || 'Could not connect to server',
                    );
                    setTimeout(() => {
                        setOutcome(null);
                        setMessage('');
                    }, 30000);
                }
            });
    };

    return (
        <main className="h-100 d-flex login-page">
            <div
                className="d-none d-lg-flex flex-column h-100 col-xl-7"
                style={{
                    backgroundColor: '#B1AEEE',
                    maxWidth: '1000px',
                }}>
                {featureImage}
                {featureTitle}
                <div
                    className={
                        'd-flex align-items-center justify-content-center'
                    }>
                    {iosButton}
                    {androidButton}
                </div>
            </div>
            <div className="bg-white col-lg-5 d-flex col-xl align-items-center justify-content-center">
                <Form
                    className="w-100 w-sm-50"
                    style={{ maxWidth: '20rem' }}
                    onSubmit={onSubmit}>
                    <div
                        className="text-center"
                        style={{
                            color: 'red',
                            display: devMessage !== '' ? 'block' : 'none',
                        }}>
                        {devMessage}
                    </div>
                    <div
                        className={
                            'w-100 d-flex align-items-center justify-content-center mb-2 logo-holder'
                        }>
                        <LogoPrimaryPositive />
                    </div>

                    <div
                        className="py-3"
                        style={{
                            display: logoutMessage ? 'block' : 'none',
                            fontSize: '14px',
                            fontWeight: 500,
                            fontFamily: 'Lato',
                            color: logoutMessageColour,
                        }}>
                        {logoutMessage}
                    </div>

                    <Form.Group controlId="auth-username">
                        <Form.Label>Login</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Member Number or Email Address"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            autoFocus={true}
                        />
                    </Form.Group>

                    <Form.Group controlId="auth-password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete='off'
                        />
                    </Form.Group>
                    <HeadShake spy={message}>
                        <Button
                            variant="secondary"
                            type="submit"
                            block
                            className={'p-2'}
                            disabled={loading}>
                            <span className={'m-5'}>
                                {loading ? (
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        as="span"
                                        size="sm"
                                    />
                                ) : (
                                    'Login'
                                )}
                            </span>
                        </Button>
                    </HeadShake>
                    <Button
                        className="d-flex mx-auto"
                        variant="link-primary-dark-1"
                        onClick={() => {
                            gtagEvent({
                                screen: 'login',
                                action: 'forgot_password',
                                label: "I've forgotten my password",
                            });
                            history.push('/forgot');
                        }}>
                        I&apos;ve forgotten my password
                    </Button>

                    <div className="py-5 text-center">
                        <div
                            className="text-primary-dark-1 font-weight-medium"
                            style={{ fontSize: '12px' }}>
                            First time using the portal?
                        </div>
                        <div>
                            <Button
                                className="p-2 mt-3"
                                variant="outline-primary-dark-1"
                                block
                                onClick={() => {
                                    gtagEvent({
                                        screen: 'login',
                                        action: 'register',
                                        label: 'Register',
                                    });
                                    history.push('/register');
                                }}
                                style={{
                                    borderColor: '#D0CFDD',
                                }}>
                                Register
                            </Button>
                        </div>
                    </div>
                    <div style={{ margin: '0px -100px' }}>
                        <Fade bottom when={outcome !== null}>
                            <StatusNotificationCard
                                status={
                                    outcome === 'fail' ? 'error' : 'success'
                                }>
                                {message}
                            </StatusNotificationCard>
                        </Fade>
                    </div>
                </Form>
            </div>

            <div
                style={{
                    fontSize: 12,
                    position: 'absolute',
                    bottom: 20,
                    right: 20,
                }}
                className={'text-light-gray font-feature'}>
                <Terms />
            </div>
        </main>
    );
};

export default AuthenticationScreen;
