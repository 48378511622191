import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Nav, Navbar } from 'react-bootstrap';
import { MdAddCircle } from 'react-icons/all';
import LogoFlat from 'img/vector/LogoFlat/comp';
import { HeaderLink } from '../buttons/HeaderLink';
import { LogoutButton } from '../buttons/LogoutButton';
import gtagEvent from 'libs/gtagEvent';
import {
    hasCover,
    hasBenefitAccount,
    hasStaffBlock,
} from 'libs/booleanHelpers';
import { setClaimDisclaimerModalVisible } from 'store';
import { StoreType } from 'store/types';
import { MembershipType, ProductType } from '@qldtuh/tuh-uh-js-api';

interface ClaimButtonProps {
    membership: MembershipType;
    product: ProductType;
}

const ClaimButton: React.FC<ClaimButtonProps> = ({ membership, product }) => {
    const dispatch = useDispatch();
    if (membership && product) {
        if (hasStaffBlock(membership)) return null;
        if (hasCover(product, 'extras') && hasBenefitAccount(membership)) {
            return (
                <div className="ml-3 my-2">
                    <Button
                        variant="new-claim"
                        onClick={() => {
                            gtagEvent({
                                screen: 'header',
                                action: 'newclaim',
                                label: 'Clicked on header New Claim button',
                                type: 'modal_open',
                            });
                            dispatch(setClaimDisclaimerModalVisible(true));
                        }}>
                        <div className="py-2">
                            <MdAddCircle size={20} /> NEW CLAIM
                        </div>
                    </Button>
                </div>
                // </Link>
            );
        }
    }

    return null;
};

const Header = () => {
    const membership = useSelector((state: StoreType) => state.membership);
    const product = useSelector((state: StoreType) => state.product);

    return (
        <>
            <Navbar
                sticky="top"
                collapseOnSelect
                expand="xl"
                bg="primary-dark-1"
                variant="dark">
                <Navbar.Brand href="/">
                    <LogoFlat />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse
                    id="responsive-navbar-nav"
                    className="justify-content-end">
                    <Nav className="">
                        <HeaderLink
                            to="/member"
                            onClick={() => {
                                gtagEvent({
                                    screen: 'header',
                                    action: 'overview',
                                    label: 'navigated to Overview page',
                                    type: 'header',
                                });
                            }}>
                            Overview
                        </HeaderLink>
                        <HeaderLink
                            to="/member/cover"
                            onClick={() => {
                                gtagEvent({
                                    screen: 'header',
                                    action: 'cover',
                                    label: 'navigated to Cover page',
                                    type: 'header',
                                });
                            }}>
                            Cover
                        </HeaderLink>
                        <HeaderLink
                            to="/member/membership"
                            onClick={() => {
                                gtagEvent({
                                    screen: 'header',
                                    action: 'membership',
                                    label: 'navigated to Membership page',
                                    type: 'header',
                                });
                            }}>
                            Membership
                        </HeaderLink>
                        <HeaderLink
                            to="/member/claim"
                            onClick={() => {
                                gtagEvent({
                                    screen: 'header',
                                    action: 'claim',
                                    label: 'navigated to Claim page',
                                    type: 'header',
                                });
                            }}>
                            Claim
                        </HeaderLink>
                        <HeaderLink
                            to="/member/wellbeing"
                            onClick={() => {
                                gtagEvent({
                                    screen: 'header',
                                    action: 'wellbeing',
                                    label: 'navigated to Wellbeing page',
                                    type: 'header',
                                });
                            }}>
                            Wellbeing
                        </HeaderLink>
                        <HeaderLink
                            to="/member/contact"
                            onClick={() => {
                                gtagEvent({
                                    screen: 'header',
                                    action: 'contact',
                                    label: 'navigated to Contact page',
                                    type: 'header',
                                });
                            }}>
                            Contact
                        </HeaderLink>
                        <LogoutButton />
                        <ClaimButton
                            membership={membership}
                            product={product}
                        />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
};

export default Header;
