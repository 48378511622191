import React from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';
import GlobalConfig from '../../../../../src/config.json';
const CoverMeUntil = ({
    isVisible,
    setFieldValue,
    values,
    checkPremium,
    premiumDate,
}) => {
    const show = isVisible ? 'block' : 'none';
    let errors = null;

    //having the NO_ZERO_PAY_ERROR here saves over complicating the Yup Schema with fetch requests
    const NO_ZERO_PAY_ERROR =
        'Cannot pay an amount of $0. Please choose a date further in the future.';
    if (premiumDate === '0') errors = NO_ZERO_PAY_ERROR;
    return (
        <div
            style={{
                display: show,
            }}>
            <div
                style={{
                    padding: '15px',
                    boxShadow: 'inset 0px -2px 0px 0px #E6E6EE',
                }}>
                <Form.Group controlId={'payment-amount-contribution-date'}>
                    <Form.Label>Cover me until:</Form.Label>
                    <DatePicker
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        selected={values?.contributiondate ?? ''}
                        minDate={moment().toDate()}
                        maxDate={moment().add(GlobalConfig.Cover.limit, GlobalConfig.Cover.type).toDate()}
                        onChange={(e) => {
                            const outDate = moment(e).format('YYYY-MM-DD');
                            setFieldValue('contributiondate', e);
                            checkPremium(outDate, 'contributiondate');
                        }}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />
                    {errors && (
                        <StatusNotificationCard status="error">
                            {errors}
                        </StatusNotificationCard>
                    )}
                </Form.Group>

                <div
                    className="font-feature text-black font-weight-medium text-uppercase"
                    style={{
                        fontSize: '12px',
                    }}>
                    Payment required:&nbsp;
                    <span
                        id="contributiondate"
                        className="font-weight-bold text-primary-dark-1"
                        style={{ fontSize: '15px' }}>
                        ${premiumDate}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default CoverMeUntil;
