import React, { useEffect, useState } from 'react';
import { WarningMessage } from './WarningMessage';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

export const MustTakeCover = () => {
    const { values } = useFormikContext();
    const [show, setShow] = useState(false);
    const dependantEndAge = useSelector((state) => state.settings.general.dependant_age);
    const dependantURL = useSelector((state) => state.settings.general.ex_dependant_url);

    useEffect(() => {
        if (['Ptnr', 'Sps', 'Membr'].includes(values.relationship)) {
            setShow(false);
        } else {
            setShow(moment().diff(values.dob, 'years') >= parseInt(dependantEndAge));
        }
    }, [values]);

    function ordinal_suffix_of(iii) {
        var jjj = iii % 10,
            kkk = iii % 100;
        if (jjj == 1 && kkk != 11) {
            return iii + "st";
        }
        if (jjj == 2 && kkk != 12) {
            return iii + "nd";
        }
        if (jjj == 3 && kkk != 13) {
            return iii + "rd";
        }
        return iii + "th";
    }

    return (
        <>
            {show && (
                <WarningMessage
                    title="This person must take out their own cover."
                    buttonText="Find out more"
                    buttonOnclick={() => {
                        window.open(
                            dependantURL,
                            '_blank',
                        ); //to open new page
                    }}>
                    <div>
                        Persons after the eve of their {ordinal_suffix_of(parseInt(dependantEndAge))} birthday,
                        cannot be covered as a dependent. Alternatively If this
                        person is interested in taking out cover, they can
                        review our options{' '}
                        <a
                            href={dependantURL}
                            target="_blank"
                            rel="noopener noreferrer">
                            here
                        </a>{' '}
                        or contact us on 1300 661 283. We can also arrange a
                        call back if you provide us with this person’s email
                        address and phone number by clicking the button to
                        discuss cover options.
                    </div>
                </WarningMessage>
            )}
        </>
    );
};

export default MustTakeCover;
