import CoverStatusBadge from 'components/elements/badges/CoverStatusBadge';
import PaymentMethodBadge from 'components/elements/badges/PaymentMethodBadge';
import { default as ChangeButton } from 'components/elements/buttons/Change';
import CardHorizontalRule from 'components/elements/divisions/CardHorizontalRule';
import CardVerticalBorderContainer from 'components/elements/divisions/CardVerticalBorderContainer';
import LabelledBoldTextBlock from 'components/elements/labels/LabelledBoldTextBlock';
import LabelledContent from 'components/elements/labels/LabelledContent';
import MakeAContributionModal from 'components/modals/MakeAContributionModal/MakeAContributionModal';
import Mastercard from 'img/vector/Mastercard/comp';
import Visa from 'img/vector/Visa/comp';
import gtagEvent from 'libs/gtagEvent';
import {
  getPaymentDetailsDebit,
  getPaymentMethodDebit,
  ymdToDate,
} from 'libs/helpers';
import { getPaymentObj } from 'libs/premiumHelpers';
import React, { useMemo, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setPaymentMethodModalVisible } from 'store';

const CardPicture = ({ type, style }) => {
  switch (type) {
    default:
      return null;
    case 'Mastercard':
      return <Mastercard style={style} />;
    case 'Visa':
      return <Visa style={style} />;
  }
};

export function MembershipContributions() {
  const dispatch = useDispatch();
  const membership = useSelector((state) => state.membership);
  const premium = useSelector((state) => state.premium);
  const [modalVisible, setModalVisible] = useState(false);

  const payment = useMemo(
    () => getPaymentObj(premium, membership?.Contribution?.Frequency),
    [premium, membership],
  );
  const paymentDetails = useMemo(
    () => getPaymentDetailsDebit(membership),
    [membership],
  );

  const debitAccounts = useSelector((state) => state.paymentMethods?.debitAccounts);
  const account = debitAccounts?.[0];

  let cardNum;
  if (account?.chargeCard?.cardNumber) {
      cardNum =
          '**' +
          account?.chargeCard?.cardNumber.substring(
              account?.chargeCard?.cardNumber
                  .length - 3,
          );
  }

  let cardType;
  if (account?.accountTypeCode === 'Mcard') {
      cardType = 'Mastercard';
  }
  if (account?.accountTypeCode === 'Visa') {
      cardType = 'Visa';
  }

  const chargeCard = account?.chargeCard;
  const cardExpiration = `${chargeCard?.expiryMonth}/${chargeCard?.expiryYear}`;

  return (
    <>
      <Card className="py-4 mb-4">
        <h1 className="px-4 text-primary text-uppercase">
          Membership Contributions
        </h1>

        <div className="my-3">
          <CardHorizontalRule />
        </div>

        {/* Payment Method Row */}
        <div className="d-flex justify-content-between mx-4 mb-3">
          <LabelledContent label="Payment Method">
            <div className="font-feature">
              {paymentDetails?.ChargeCard ? (
                <>
                  <CardPicture type={cardType} style={{ height: '20px', width: 'auto', marginRight: '5px' }} />{' '}
                  {cardType} {cardNum}
                  <br />
                  <small>EXP: {cardExpiration}</small>
                </>
              ) : (
                <>
                  {paymentDetails?.name} {paymentDetails?.number}
                </>
              )}
            </div>
            <PaymentMethodBadge method={getPaymentMethodDebit(membership)} />
          </LabelledContent>

          <ChangeButton
            onClick={() => {
              gtagEvent({
                screen: 'membership',
                action: 'payment_method',
                label: 'Clicked on change payment method button',
                type: 'modal_open',
              });
              dispatch(setPaymentMethodModalVisible(true));
            }}
          />
        </div>

        {/* Next Payment Details Row */}
        <div>
          <CardVerticalBorderContainer>
            <div className="d-flex justify-content-between py-2 px-4 align-items-center flex-wrap">
              <div className="mr-2">
                <LabelledBoldTextBlock
                  label="Next Payment"
                  text={payment?.NetAmount ? `$${payment?.NetAmount}` : '-'}
                />
              </div>

              <div className="mr-2">
                <LabelledBoldTextBlock
                  label="Due Date"
                  text={ymdToDate(membership?.Contribution?.DueDate)}
                />
              </div>

              <div className="mr-2">
                <LabelledBoldTextBlock
                  label="Cover Paid To"
                  text={ymdToDate(membership?.Contribution?.PaidToDate)}
                />
              </div>
            </div>
          </CardVerticalBorderContainer>
        </div>

        {/* Pay now / Cover status */}
        <div className="d-flex justify-content-between flex-wrap align-items-center mx-4">
          {/* Pay now buttons */}
          <div className="mt-3 mr-2">
            <Button
              variant="secondary"
              onClick={() => {
                gtagEvent({
                  screen: 'membership',
                  action: 'make_a_contribution',
                  label: 'Clicked Pay Now button',
                  type: 'modal_open',
                });
                setModalVisible(true);
              }}>
              <span className="mx-3">Pay Now</span>
            </Button>
          </div>

          {/* Cover status */}
          <div className="mt-3">
            <CoverStatusBadge membership={membership} />
          </div>
        </div>
      </Card>

      {/* Modals */}
      <MakeAContributionModal
        handleClose={() => {
          gtagEvent({
            screen: 'contribution',
            action: 'cancel',
            label: 'Closed or cancelled modal',
            type: 'modal',
          });
          setModalVisible(false);
        }}
        show={modalVisible}
        align={'right'}
      />
    </>
  );
}
