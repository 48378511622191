import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

export const DependentDisclaimers = ({ loading }) => {
    const { values, errors, touched, setFieldValue } = useFormikContext();
    const dependantEndAge = useSelector((state) => state.settings.general.dependant_age);

    return (
        <div
            className="col-12 pt-3 pb-2 modal-box-shadow"
            style={{
                fontSize: '14px',
            }}>
            <ul>
                <li>
                    My dependant is aged under {dependantEndAge} years of age, unmarried and
                    not in a de-facto relationship.
                </li>
                <li>
                    I will advise Union Health immediately if my dependant’s
                    circumstances change.
                </li>
                <li>
                    I am the primary member or I have authority from the primary
                    member (Union Health may require confirmation from the primary member
                    if an authority is not already held on file)
                </li>
            </ul>
            <Form.Group
                controlId={`agreeDisclaimers`}
                className="text-label-gray font-weight-medium">
                <Form.Check
                    type="checkbox"
                    id={`agreeDisclaimers`}
                    label={`I agree to the disclaimers above*`}
                    className={
                        errors.agreeDisclaimers &&
                        touched.agreeDisclaimers &&
                        !loading &&
                        'is-invalid'
                    }
                    style={{
                        fontSize: '13px',
                    }}
                    checked={values.agreeDisclaimers === true}
                    onChange={(e) => {
                        setFieldValue('agreeDisclaimers', e.target.checked);
                    }}
                />
                {touched.agreeDisclaimers &&
                    errors.agreeDisclaimers &&
                    !loading && (
                        <Form.Control.Feedback type={'invalid'}>
                            {errors.agreeDisclaimers}
                        </Form.Control.Feedback>
                    )}
            </Form.Group>
        </div>
    );
};

export default DependentDisclaimers;
