import Cancel from 'components/elements/buttons/Cancel';
import CustomModal from 'components/modals/Modal';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { CONFIG } from 'libs/constants';
import { allAdults } from 'libs/helpers';
import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { MdCreditCard } from 'react-icons/all';
import { useDispatch, useSelector } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { useHistory } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { setMembership } from 'store';
import api from '@qldtuh/tuh-uh-js-api';
import gtagEvent from 'libs/gtagEvent';
import { StoreType } from 'store/types';

interface Props {
    gtag: () => void;
}

const LostMemberCard: React.FC<Props> = ({ gtag }) => {
    const membership = useSelector((state: StoreType) => state.membership);
    const adults = allAdults(membership);
    const dispatch = useDispatch();

    const [lostMemberCardModalVisible, setLostMemberCardModalVisible] =
        useState(false);
    const [selectedAdult, setSelectedAdult] = useState("");

    const [errorSpace, setErrorSpace] = useState('');
    const [failedAttempts, setFailedAttempts] = useState(0);

    const [successSpace, setSuccessSpace] = useState('');
    const [successAttempts, setSuccessAttempts] = useState(0);
    const history = useHistory();

    useEffect(() => {
        setSelectedAdult("");
    }, [membership]);

    const [onSubmitState, onSubmit] = useAsyncFn<any,any>(async (selectedAdult) => {
        console.log(selectedAdult);
        setFailedAttempts(0);
        setSuccessAttempts(0);

        const access_token = getAccessToken();
        const memberid = getMembershipId();

        const personid = selectedAdult;

        try {
            if(!memberid) {
                throw new Error('No member ID found');
            }
            const response = await api
                .user(CONFIG, access_token)
                .membership.requestNewCard(memberid, personid);

            if (response?.status === 'error') {
                throw new Error(response.message);
            } else {
                console.log(response);
                dispatch(setMembership(response));
                setSuccessSpace(
                    'Your new card has been queued. This should be received within the next 7 business days',
                );
                setSuccessAttempts(1);

                setTimeout(() => {
                    setSuccessSpace('');
                    setSuccessAttempts(0);
                    setLostMemberCardModalVisible(false);

                    setTimeout(() => {
                        history.push('/member/cover');
                    }, 500);
                }, 4500);
            }
        } catch (err) {
            if (err instanceof Error) {
                console.log('ERROR');
                console.log(err.message);
                setErrorSpace(err.message);
            }
            setFailedAttempts(1);
            setTimeout(() => {
                setErrorSpace('');
                setFailedAttempts(0);
            }, 10000);
        }
    });

    return (
        <>
            <Button
                className="ml-0 px-2 flex-shrink-1"
                variant="outline-gray"
                onClick={() => {
                    gtag();
                    setLostMemberCardModalVisible(true);
                }}>
                <div className="d-flex align-items-center text-primary-dark-1">
                    <div className="mr-2">
                        <MdCreditCard
                            size={20}
                            style={{ verticalAlign: 'middle' }}
                        />
                    </div>
                    <div style={{ fontSize: '13px' }}>
                        {/*Lost your member card?*/}
                        Need a new member card?
                    </div>
                </div>
            </Button>
            <CustomModal
                handleClose={() => {
                    gtagEvent({
                        screen: 'newcard',
                        action: 'cancel',
                        label: 'Cancelled or closed modal',
                        type: 'modal',
                    });
                    setLostMemberCardModalVisible(false);
                }}
                show={lostMemberCardModalVisible}
                title={'Are you sure?'}>
                <div className="mb-4 text-center">
                    <p>Requesting a new card for:</p>
                    <p>Please note: Your previous membership card will be expired.</p>
                    <p>
                        <Form.Control
                            as="select"
                            value={selectedAdult}
                            onChange={(e) => {
                                setSelectedAdult(e.target.value);
                            }}>
                            <option value="" disabled>
                            - Select Person -
                            </option>
                            {adults
                                ? adults.map((person) => (
                                      <option
                                          value={person?.Person?.PersonId}
                                          key={
                                              person?.Person?.PersonId
                                          }>{`${person.Person.GivenName} ${person.Person.Surname}`}</option>
                                  ))
                                : ''}
                        </Form.Control>
                    </p>
                </div>
                <div className="d-flex justify-content-center">
                    <Button
                        variant="secondary"
                        style={{
                            padding: '9px',
                            marginRight: '10px',
                        }}
                        onClick={() => {
                            gtagEvent({
                                screen: 'newcard',
                                action: 'submit',
                                label: 'requested new card',
                                type: 'modal',
                            });
                            onSubmit(selectedAdult);
                        }}
                        disabled={onSubmitState.loading || !selectedAdult}>
                        {onSubmitState.loading ? (
                            <>
                                <Spinner
                                    animation="border"
                                    role="status"
                                    as="span"
                                    size="sm"
                                />
                                Request new card
                            </>
                        ) : (
                            'Request new card'
                        )}
                    </Button>
                    <Cancel
                        style={{
                            color: '#9998A8',
                        }}
                        onClick={() => {
                            setLostMemberCardModalVisible(false);
                        }}
                    />
                </div>
                <span
                    className={
                        'text-danger d-block text-center mb-1 mt-4 font-feature'
                    }>
                    <Fade collapse top when={failedAttempts > 0}>
                        {errorSpace}
                    </Fade>
                </span>
                <span
                    className={
                        'text-success d-block text-center mb-1 mt-4 font-feature'
                    }>
                    <Fade collapse top when={successAttempts > 0}>
                        {successSpace}
                    </Fade>
                </span>
            </CustomModal>
        </>
    );
};

export default LostMemberCard;
