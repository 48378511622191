import { getAccessToken, getMembershipId } from 'libs/auth';
import { CONFIG } from 'libs/constants';
import gtagEvent from 'libs/gtagEvent';
import React, { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';
import api from '@qldtuh/tuh-uh-js-api';
import { WellbeingBenefit } from 'types/wellbeing';

const WellbeingPage: React.FC = () => {
    const [benefits, setBenefits] = useState<WellbeingBenefit[]>([]);

    useEffect(() => {
        benefitGet();
    }, []);

    const [benefitGetState, benefitGet] = useAsyncFn(async () => {
        const memberid = getMembershipId();
        const access_token = getAccessToken();
        try {
            if (!memberid) {
                throw new Error('No memberid found');
            }
            const response = await api
                .website(CONFIG, access_token)
                .wellbeingBenefits(memberid);
            if (response?.status === 'error') {
                throw new Error(response.message);
            } else {
                console.log(response);
                setBenefits(response);
            }
        } catch (err) {
            console.log('useAsyncFn is probably not being used correctly', {
                benefitGetState,
            });
            console.log('ERROR');
            console.log(err);
        }
    });

    return (
        <main className="container-fluid p-4 wellbeing-page">
            <div className="row">
                <div className="container">
                    <div className="row flex-wrap">
                        <div className="col-12 py-5 col-md-10 offset-md-1">
                            <h1>Mates Rates</h1>
                            <h2 className="text-center">
                                Union Health exists to serve our members.
                                Connecting you with great discounts on the
                                brands you love is another way we deliver great
                                value to you and your family. Check out what our
                                Mates have to offer.
                            </h2>
                        </div>
                        <div className="w-100"></div>
                        {benefits.length > 0
                            ? benefits.map((item, i) => {
                                  return (
                                      <div
                                          key={i}
                                          className="col-12 col-sm-6 col-md-4 item">
                                          <div className="inner">
                                              <div className="display-image">
                                                  <img
                                                      src={
                                                          item?.display_image
                                                              ?.url
                                                      }
                                                      className="img-fluid"
                                                      alt={
                                                          item?.display_image
                                                              ?.alt
                                                      }
                                                  />
                                              </div>
                                              <div className="title">
                                                  <h3>{item.title}</h3>
                                              </div>
                                              <div
                                                  className="body"
                                                  dangerouslySetInnerHTML={{
                                                      __html: item?.member_body,
                                                  }}
                                                  onClick={() => {
                                                      gtagEvent({
                                                          screen: 'wellbeing',
                                                          action: `referralfor_${item.title
                                                              .toLowerCase()
                                                              .replace(
                                                                  ' ',
                                                                  '_',
                                                              )}`,
                                                          label: `User clicked on the benefit card for ${item.title}`,
                                                      });
                                                  }}></div>
                                              <div className="logo">
                                                  <img
                                                      src={item?.logo?.url}
                                                      className="img-fluid"
                                                      alt={item?.logo?.alt}
                                                  />
                                              </div>
                                          </div>
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default WellbeingPage;
