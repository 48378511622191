export default {
    SET_PAYMENT_METHOD_MODAL_VISIBLE: 'set_payment_method_modal_visible',
    SET_EMAIL_CONFIRMATION_MODAL_VISIBLE:
        'set_email_confirmation_modal_visible',
    SET_EDIT_PERSON_MODAL_VISIBLE: 'set_edit_person_modal_visible',
    SET_HRA_MODAL_VISIBLE: 'set_hra_modal_visible',
    SET_SUCCESS_MODAL_VISIBLE: 'set_success_modal_visible',
    SET_LOGOUT_MESSAGE: 'set_logout_message',
    SET_LOGOUT_MESSAGE_COLOUR: 'set_logout_message_colour',
    SET_TERMINATED_MODAL_VISIBLE: 'set_terminated_modal_visible',
    SET_REFERAFRIEND_MODAL_VISIBLE: 'set_referafriend_modal_visible',
    SET_CLAIMPAYMENT_MODAL_VISIBLE: 'set_claimpayment_modal_visible',
    SET_BENEFITCALCULATOR_MODAL_VISIBLE: 'set_benefitcalculator_modal_visible',
    SET_REBATEFORM_MODAL_VISIBLE: 'set_rebateform_modal_visible',
    SET_CLAIMDISCLAIMER_MODAL_VISIBLE: 'set_claimdisclaimer_modal_visible',
    SET_ONLINECLAIMSERVICES_MODAL_VISIBLE:
        'set_onlineclaimservices_modal_visible',
};
