import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';
import CustomModal from 'components/modals/Modal';
import { Formik } from 'formik';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { CONFIG } from 'libs/constants';
import gtagEvent from 'libs/gtagEvent';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { useAsyncFn } from 'react-use';
import { setMembership } from 'store/membership/actions';
import api from '@qldtuh/tuh-uh-js-api';
import { HomeOnlySchema, BothAddressSchema } from './validation';
import { getDefaultValues, generatePayload } from './helpers';
import MemberDetailsForm from './MemberDetailsForm';

const MembershipDetailsModal = ({ show, handleClose, align }) => {
    const dispatch = useDispatch();

    const membership = useSelector((state) => state.membership);
    const [usePostalSchema, setUsePostalSchema] = useState(false);
    const [message, setMessage] = useState('');
    const [outcome, setOutcome] = useState(null);
    const [defaults, home, postal] = getDefaultValues(
        membership,
        !usePostalSchema,
    );

    useEffect(() => {
        setUsePostalSchema(home !== postal);
    }, [membership]);

    const [onSubmitState, onSubmit] = useAsyncFn(async (values) => {
        gtagEvent({
            screen: 'membershipdetails',
            action: 'submit',
            label: 'Clicked on a persons eHealth Profile link',
            type: 'modal',
        });

        const access_token = getAccessToken();
        const memberid = getMembershipId();
        const payload = generatePayload(values);

        try {
            const response = await api
                .user(CONFIG, access_token)
                .membership.updateMembership(memberid, payload);

            if (response?.status === 'error') {
                throw new Error(response);
            } else {
                console.log(response);
                dispatch(setMembership(response));
                setMessage(response.message || 'Updated membership successfully.');
                setOutcome('success');
                setTimeout(() => {
                    setOutcome(null);
                    setMessage('');
                }, 10000);
            }
        } catch (err) {
            console.log('ERROR');
            console.log(err);
            setOutcome('error');
            setMessage(err.message || 'Could not connect to server');
            setTimeout(() => {
                setOutcome(null);
                setMessage('');
            }, 10000);
        }
    });

    return (
        <CustomModal
            handleClose={handleClose}
            align={align}
            show={show}
            title={'Membership Details'}>
            <div className={'text-primary font-feature row row-cols-1'}>
                <Formik
                    validateOnChange={true}
                    validateOnBlur={true}
                    validateOnMount={true}
                    initialValues={defaults || null}
                    validationSchema={
                        usePostalSchema ? BothAddressSchema : HomeOnlySchema
                    }
                    onSubmit={(values, { resetForm }) =>
                        onSubmit(values, resetForm)
                    }>
                    <MemberDetailsForm
                        onSubmitState={onSubmitState}
                        handleClose={handleClose}
                        updateSchema={{ usePostalSchema, setUsePostalSchema }}
                    />
                </Formik>
                <div style={{ paddingInline: '10px' }}>
                    <Fade collapse bottom when={outcome !== null}>
                        <StatusNotificationCard status={outcome}>
                            {message}
                        </StatusNotificationCard>
                    </Fade>
                </div>
            </div>
        </CustomModal>
    );
};

export default MembershipDetailsModal;
