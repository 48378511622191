import React from "react";
import {MdEdit} from "react-icons/all";
import LinkOutlineTextIcon from "./LinkOutlineTextIcon";

const Change = ({onClick}) => {
    return (
        <LinkOutlineTextIcon
            text="CHANGE"
            icon={<MdEdit/>}
            iconSide="left"
            className={"text-primary"}
            onClick={onClick}
        />
    );
};

export default Change;

