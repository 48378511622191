import Edit from 'components/elements/buttons/Edit';
import CardHorizontalRule from 'components/elements/divisions/CardHorizontalRule';
import AddPersonModal from 'components/modals/PersonsCoveredModals/AddPersonModal/AddPersonModal';
import gtagEvent from 'libs/gtagEvent';
import { allPersons, isOver18, ymdToSlashFormatDate } from 'libs/helpers';
import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { GiPerson, MdAddCircle, MdInsertChart } from 'react-icons/all';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    setEditPersonModalVisible,
    setHraModalVisible,
    setSelectedUser,
} from 'store';
import { StoreType } from 'store/types';
import styled from 'styled-components';

const UsageOverview = ({ onClick }) => {
    return (
        <Button onClick={onClick} variant="link-primary text-primary p-0">
            <div className="d-flex align-items-center justify-content-between">
                <MdInsertChart size={20} style={{ verticalAlign: 'middle' }} />
                <span className="ml-1 text-primary-dark-1">Usage Overview</span>
            </div>
        </Button>
    );
};

const HealthEProfile = ({ onClick }) => {
    return (
        <Button onClick={onClick} variant="link-primary text-primary p-0">
            <div className="d-flex align-items-center justify-content-between">
                <GiPerson size={20} style={{ verticalAlign: 'middle' }} />
                <span className="ml-1 text-primary-dark-1">
                    Health-e-profile
                </span>
            </div>
        </Button>
    );
};

const InitialBubble = styled.div`
    border-radius: 100%;
    font-size: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 2rem;
`;

const Person = ({ person }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <>
            <div>
                <div className="d-flex justify-content-between align-items-end my-3 px-3">
                    {/* Columns */}
                    <div className="d-flex flex-row flex-wrap">
                        {/* Initials */}
                        <InitialBubble className="mr-2 bg-primary-dark-1 text-light font-weight-semibold mb-2">
                            {`${person.Person.GivenName[0]}${person.Person.Surname[0]}`.toUpperCase()}
                        </InitialBubble>

                        {/* Name + DOB Stack */}
                        <div className="flex-wrap">
                            {/* Full Name */}
                            <h5 className="mr-4 font-feature mb-0">
                                {`${person.Person.GivenName} ${person.Person.Surname}`}
                            </h5>

                            {/* DOB */}
                            <small>
                                {ymdToSlashFormatDate(
                                    person.Person.BirthDate,
                                ) || '-'}
                            </small>

                            {/* Usage */}
                            <div className="mt-1">
                                <UsageOverview
                                    onClick={() => {
                                        gtagEvent({
                                            screen: 'cover',
                                            action: 'usageoverview',
                                            label: 'Clicked on a persons usage overview link',
                                        });
                                        dispatch(
                                            setSelectedUser(
                                                person.Person.PersonId,
                                            ),
                                        );
                                        history.push('/member');
                                    }}
                                />
                            </div>

                            {/*Health e Profile / HRA*/}
                            <div className="mt-1">
                                {isOver18(person.Person.BirthDate) ? (
                                    <HealthEProfile
                                        onClick={() => {
                                            gtagEvent({
                                                screen: 'cover',
                                                action: 'ehealthprofile',
                                                label: 'Clicked on a persons eHealth Profile link',
                                                type: 'modal_open',
                                            });
                                            dispatch(
                                                setSelectedUser(
                                                    person.Person.PersonId,
                                                ),
                                            );
                                            dispatch(setHraModalVisible(true));
                                        }}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>

                    <Edit
                        className={'text-primary'}
                        onClick={() => {
                            gtagEvent({
                                screen: 'cover',
                                action: 'edit_person_covered',
                                label: 'Clicked on edit person button',
                                type: 'modal_open',
                            });
                            dispatch(setSelectedUser(person.Person.PersonId));
                            dispatch(setEditPersonModalVisible(true));
                        }}
                    />
                </div>

                <CardHorizontalRule />
            </div>
        </>
    );
};

const PersonsCovered = () => {
    const [addAPersonModalVisible, setAddAPersonModalVisible] = useState(false);

    const membership = useSelector((state: StoreType) => state.membership);

    const PEOPLE = allPersons(membership);

    return (
        <>
            <Card className="pt-4 mb-2 h-100">
                <h1 className="mb-4 px-4 mx-1 text-primary text-uppercase">
                    Persons Covered
                </h1>

                <CardHorizontalRule />

                {PEOPLE
                    ? PEOPLE.map((person) => (
                          <Person
                              person={person}
                              key={person?.Person?.PersonId}
                          />
                      ))
                    : ''}
                <Button
                    variant="link-primary-dark-1 mt-2 mb-2 text-secondary"
                    onClick={() => {
                        gtagEvent({
                            screen: 'cover',
                            action: 'add_person',
                            label: 'Clicked on add person button',
                            type: 'modal_open',
                        });
                        setAddAPersonModalVisible(true);
                    }}>
                    <div className="d-flex align-items-center">
                        <span className="mr-1">ADD PERSON</span>

                        <MdAddCircle size={20} />
                    </div>
                </Button>
            </Card>

            <AddPersonModal
                handleClose={() => {
                    gtagEvent({
                        screen: 'addperson',
                        action: 'cancel',
                        label: 'Cancelled or closed modal',
                        type: 'modal',
                    });
                    setAddAPersonModalVisible(false);
                }}
                show={addAPersonModalVisible}
                align={'right'}
            />
        </>
    );
};

export default PersonsCovered;
