import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import api from '@qldtuh/tuh-uh-js-api';
import {CONFIG} from "../../../libs/constants";
import {getAccessToken} from "../../../libs/auth";
import React, {useState} from "react";
import {HeaderLink} from "./HeaderLink";
import {FaUserCircle} from "react-icons/all";
import {Spinner} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {thunkLogout,setLogoutMessage} from "store";
import { StoreType } from "store/types";

const StandardContents = () => {
    return (
        <>
            <FaUserCircle
                size={20}
                style={{
                    paddingBottom: '4px',
                    marginRight: '5px'
                }}
            />
            Logout
        </>
    )
}

const LoadingContents = () => {
    return (
        <>
            <Spinner animation="border" role="status" as="span" size="sm" style={{
                marginRight: '9px'
            }}/>
            Logout
        </>
    )
}


export const LogoutButton = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((store: StoreType) => store.user)
    const [hover, setHover] = useState(false);
    const [loading, setLoading] = useState(false);

    const onLogoutClick = () => {

        setLoading(true);


        // TODO: For now, we will kill tokens even if the server did not respond to our logout request
        api.user(CONFIG, getAccessToken()).logout(user?.memberId).then(() => {
            dispatch(setLogoutMessage('You have been successfully logged out.'));
            dispatch(thunkLogout())
            setLoading(false)
            history.replace('/')
        }).catch((e)=>{
           // TODO!
           // alert('Error')
           console.log(e.message)
        });
    };

    return (
        <HeaderLink
            textClass={"ml-3 my-1"}
            onClick={()=>onLogoutClick()}
            to="#"
        >
            <span
                style={{
                    borderRadius: '100px',
                    padding: '0.6rem',
                    transition: 'width 2s ease',
                    display: 'flex',
                    flexWrap: 'nowrap'
                }}

                className={`${hover ? 'bg-primary-dark-2' : 'bg-secondary'} text-white`}
                onMouseEnter={()=>setHover(true)}
                onMouseLeave={()=>setHover(false)}
            >
                {loading ? <LoadingContents/> : <StandardContents/>}
            </span>
        </HeaderLink>
    )
}
