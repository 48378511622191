import { useFormikContext } from 'formik';
import { useRef } from 'react';

export const usePlacesAutocomplete = (type = 'home') => {
    const autocompleteRef = useRef(null);
    const { setFieldValue } = useFormikContext();

    const placesSDK = new window.google.maps.places.Autocomplete(
        autocompleteRef.current,
        { types: ['address'], componentRestrictions: { country: 'au' } },
    );
    const callback = async () => {
        const autocomplete = await handlePlaceSelect(placesSDK, type);
        for (const field in autocomplete) {
            setFieldValue(field, autocomplete[field]);
        }
    };

    placesSDK && placesSDK.addListener('place_changed', () => callback());

    return autocompleteRef;
};

const handlePlaceSelect = async (placesSDK, type) => {
    if (!placesSDK) return;
    const addressObject = placesSDK.getPlace();

    // Full address object
    const placeAddress = addressObject.address_components;

    // These fields can be safely undefined
    const subPremise = placeAddress.find((component) =>
        component.types.includes('subpremise'),
    )?.long_name;

    // These fields will throw if not defined
    const postCode = placeAddress.find((component) =>
        component.types.includes('postal_code'),
    ).long_name;
    const suburb = placeAddress.find((component) =>
        component.types.includes('locality'),
    ).short_name;
    const state = placeAddress.find((component) =>
        component.types.includes('administrative_area_level_1'),
    ).short_name;
    const streetName = placeAddress.find((component) =>
        component.types.includes('route'),
    ).long_name;
    const streetNumber = placeAddress.find((component) =>
        component.types.includes('street_number'),
    ).long_name;

    // Addresses might not have an appt number
    const fullStreetNumber = subPremise
        ? `${subPremise}/${streetNumber}`
        : streetNumber;

    return {
        [`${type}Line1`]: `${fullStreetNumber} ${streetName}`,
        [`${type}Suburb`]: suburb,
        [`${type}State`]: state,
        [`${type}Postcode`]: postCode,
    };
};
