import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG } from 'libs/constants';

// const membership = useSelector((state) => state.membership);

export const getMembershipEmailDetails = (user, membership) => {
    return {
        member: getMemberEmailDetails(membership),
        user: getPartnerSpouseEmailDetails(user),
    };
};

export const getUserEmailDetails = (user, membership) => {
    if (user?.RelationshipCode === 'Membr')
        return getMemberEmailDetails(membership);
    return getPartnerSpouseEmailDetails(user);
};

const getMemberEmailDetails = (membership) => {
    let EmailAddress = null;
    let IsConsentToEmail = false;
    
    if (membership && membership.EmailAddresses && membership.EmailAddresses.EmailAddress) {
      const emailAddressArray = membership.EmailAddresses.EmailAddress;
      if (emailAddressArray?.length > 0) {
        EmailAddress = emailAddressArray[0]?.EmailAddress;
        IsConsentToEmail = emailAddressArray[0]?.IsConsentToEmail ?? false;
      }
    }
    return { EmailAddress, IsConsentToEmail };
};

const getPartnerSpouseEmailDetails = (user) => {
    let EmailAddress = null;
    let IsConsentToEmail = false;
    const emailAddressArray = user?.Person?.EmailAddresses?.EmailAddress;
    if (emailAddressArray?.length > 0) {
        EmailAddress = emailAddressArray[0]?.EmailAddress ?? null;
        IsConsentToEmail = emailAddressArray[0]?.IsConsentToEmail ?? false;
    }
    return { EmailAddress, IsConsentToEmail };
};

export const updateUserEmailDetails = async (data) => {
    const { confirmUser } = data;
    data.consentemail = data.consent ? '1' : '0';
    const confirmUserId = confirmUser?.Person?.PersonId;
    data.confirmUserId = confirmUserId;
    console.log({ confirmUserId });

    if (confirmUser?.RelationshipCode === 'Membr') {
        return await updateMemberEmailDetails(data);
    }

    return await updatePersonDetails(data);
};

const updateMemberEmailDetails = async ({
    memberid,
    access_token,
    email,
    consentemail,
    confirmUserId,
}) => {
    const payload = { email, consentemail };
    const emailResponse = await api
        .user(CONFIG, access_token)
        // this endpoint updates top level
        .membership.updateMembership(memberid, payload);

    if (emailResponse?.status === 'error')
        throw new Error({
            msg: 'occurred @ membership email update [Membr]',
            err: emailResponse,
        });

    payload.personid = confirmUserId;
    // console.log({ payload }, { confirmUser });

    const personResponse = await api
        .user(CONFIG, access_token)
        // this endpoint updates specific person
        .person.editPerson(memberid, payload);

    if (personResponse?.status === 'error')
        throw new Error({
            msg: 'occurred @ person email update [Membr]',
            err: personResponse,
        });

    return emailResponse;
};

const updatePersonDetails = async ({
    memberid,
    access_token,
    email,
    consentemail,
    confirmUserId,
}) => {
    const payload = { email, consentemail };
    payload.personid = confirmUserId;
    const personResponse = await api
        .user(CONFIG, access_token)
        // this endpoint updates specific person
        .person.editPerson(memberid, payload);

    if (personResponse?.status === 'error') {
        throw new Error({
            msg: 'occurred @ person email update [Ptnr|Sps]',
            err: personResponse,
        });
    }
        
    return personResponse;
};
