import { Formik } from 'formik';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { CONFIG } from 'libs/constants';
import gtagEvent from 'libs/gtagEvent';
import React, { useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { useAsyncFn } from 'react-use';
import api from '@qldtuh/tuh-uh-js-api';
import * as Yup from 'yup';

export function ChangePassword() {
    const [errorSpace, setErrorSpace] = useState('');
    const [failedAttempts, setFailedAttempts] = useState(0);

    const [successSpace, setSuccessSpace] = useState('');
    const [successAttempts, setSuccessAttempts] = useState(0);

    const idPrefix = 'change-password';

    const submitCallback = async (values, resetForm) => {
        console.log(values);

        const access_token = getAccessToken();
        const memberid = getMembershipId();

        try {
            if (!memberid) {
                throw new Error('No member id found');
            }
            const response = await api
                .user(CONFIG, access_token)
                .changePassword(
                    memberid,
                    values.currentPassword,
                    values.newPassword,
                );

            if (response?.status == 'error') {
                throw new Error(response.message);
            } else {
                setSuccessSpace(
                    'Password changed. It will take about 2 minutes for new password to take effect',
                );
                setSuccessAttempts(1);
                setTimeout(function () {
                    setSuccessAttempts(0);
                    setSuccessSpace('');
                }, 5000);
            }
        } catch (err) {
            if (err  instanceof Error) {
                console.log('ERROR');
                console.log(err.message);
                setErrorSpace(err.message);
            }
            setTimeout(function () {
                setFailedAttempts(0);
                setErrorSpace('');
            }, 5000);
        }

        resetForm();
    }

    const [onSubmitState, onSubmit] = useAsyncFn<any,any>(submitCallback);

    return (
        <Card className="p-4">
            <h1 className={'text-primary text-uppercase'}>Change Password</h1>
            <Formik
                validateOnChange={true}
                validateOnBlur={true}
                validateOnMount={true}
                initialValues={{
                    currentPassword: '',
                    newPassword: '',
                    newPasswordConfirm: '',
                }}
                onSubmit={(values, { resetForm }) => {
                    gtagEvent({
                        screen: 'membership',
                        action: 'update_password',
                        label: 'Submitted new password',
                    });
                    onSubmit(values, resetForm);
                }}
                validationSchema={Yup.object().shape({
                    currentPassword: Yup.string().required(
                        'You must enter your current password.',
                    ),
                    newPassword: Yup.string()
                        .required('A new password is required.')
                        .min(8, 'Password must be at least 8 characters.')
                        .max(
                            20,
                            'Password must be no longer than 20 characters.',
                        )
                        .matches(
                            /[A-Z]/,
                            'Password must contain at least one uppercase character.',
                        )
                        .matches(
                            /[a-z]/,
                            'Password must contain at least one lowercase character.',
                        )
                        .matches(
                            /[0-9]/,
                            'Password must contain at least one number.',
                        )
                        .test({
                            name: 'no-illegal',
                            message:
                                'Password cannot contain <, >, &, # or spaces.',
                            test: (value) => {
                                if (!value) return true;
                                return !(
                                    value.includes('#') ||
                                    value.includes('&') ||
                                    value.includes('<') ||
                                    value.includes('>') ||
                                    value.includes(' ')
                                );
                            },
                        }),
                    newPasswordConfirm: Yup.string()
                        .required('You must confirm your password.')
                        .oneOf(
                            [Yup.ref('newPassword'), null],
                            'Passwords do not match.',
                        ),
                })}>
                {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isValid,
                    } = props;
                    return (
                        <Form>
                            <Form.Group
                                controlId={`${idPrefix}-current-password`}>
                                <Form.Label>Current Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="currentPassword"
                                    className={
                                        errors.currentPassword &&
                                        touched.currentPassword &&
                                        !onSubmitState.loading
                                            ? 'is-invalid'
                                            : ''
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.currentPassword}
                                    autoComplete='off'
                                />
                                {touched.currentPassword &&
                                errors.currentPassword &&
                                !onSubmitState.loading ? (
                                    <Form.Control.Feedback type={'invalid'}>
                                        {errors.currentPassword}
                                    </Form.Control.Feedback>
                                ) : null}
                            </Form.Group>
                            <Form.Group controlId={`${idPrefix}-new-password`}>
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="newPassword"
                                    className={
                                        errors.newPassword &&
                                        touched.newPassword &&
                                        !onSubmitState.loading
                                            ? 'is-invalid'
                                            : ''
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.newPassword}
                                    autoComplete='new-password'
                                />
                                {touched.newPassword &&
                                errors.newPassword &&
                                !onSubmitState.loading ? (
                                    <Form.Control.Feedback type={'invalid'}>
                                        {errors.newPassword}
                                    </Form.Control.Feedback>
                                ) : null}
                            </Form.Group>
                            <Form.Group
                                controlId={`${idPrefix}-confirm-password`}>
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="newPasswordConfirm"
                                    className={
                                        errors.newPasswordConfirm &&
                                        touched.newPasswordConfirm &&
                                        !onSubmitState.loading
                                            ? 'is-invalid'
                                            : ''
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.newPasswordConfirm}
                                    autoComplete='new-password'
                                />
                                {touched.newPasswordConfirm &&
                                errors.newPasswordConfirm &&
                                !onSubmitState.loading ? (
                                    <Form.Control.Feedback type={'invalid'}>
                                        {errors.newPasswordConfirm}
                                    </Form.Control.Feedback>
                                ) : null}
                            </Form.Group>

                            <Button
                                variant="secondary"
                                type="submit"
                                onClick={(e: React.MouseEvent<HTMLFormElement, MouseEvent>) => handleSubmit(e)}
                                disabled={!isValid}>
                                {onSubmitState.loading ? (
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        as="span"
                                        size="sm"
                                    />
                                ) : (
                                    'Save Changes'
                                )}
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
            <span
                className={'text-danger text-center mb-1 mt-4 font-feature'}
                style={{
                    display: failedAttempts ? 'block' : 'none',
                }}>
                <Fade collapse top when={failedAttempts > 0}>
                    {errorSpace}
                </Fade>
            </span>
            <span
                className={'text-success text-center mb-1 mt-4 font-feature'}
                style={{
                    display: successAttempts ? 'block' : 'none',
                }}>
                <Fade collapse top when={successAttempts > 0}>
                    {successSpace}
                </Fade>
            </span>
        </Card>
    );
}
