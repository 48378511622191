import { schoolYearHelpers } from './schoolYear';

const moment = require('moment');

const dateFormat = 'YYYY-MM-DD';

export const dependantHelpers = {
    /**
     * Returns people who satisfy:
     * 1. Is Dep
     * 2a. IS NOT registered as Ext or Student and turning 21 soon
     * OR
     * 2b. IS registered as Ext or Student and turning 31 soon
     * @param people
     */
    getExpiringDependents: (people, dependantEndAge=31) => {
        const expiring = [];

        if (typeof people?.forEach === 'function') {
            people.forEach((person) => {
                if (!dependantHelpers.isMemberOrPartner(person)) {
                    const isStuDep =
                        dependantHelpers.isStudentDependant(person);
                    const isExtDep =
                        dependantHelpers.isExtendedDependant(person, dependantEndAge);

                    const isPenDep =
                        dependantHelpers.isPendingDependant(person);

                    const isTwentyOneSoon = dependantHelpers.isTurningAgeSoon(
                        person,
                        21,
                    );
                    const isThirtyOneSoon = dependantHelpers.isTurningAgeSoon(
                        person,
                        dependantEndAge,
                    );

                    if (
                        isTwentyOneSoon &&
                        !isStuDep &&
                        !isExtDep &&
                        !isPenDep
                    ) {
                        expiring.push({
                            ...person,
                            isTwentyOneSoon: true,
                        });
                    } else if (
                        isThirtyOneSoon &&
                        !isStuDep &&
                        !isExtDep &&
                        !isPenDep
                    ) {
                        expiring.push({
                            ...person,
                            isThirtyOneSoon: true,
                        });
                    }
                }
            });
        }

        return expiring;
    },

    /**
     * Get all student dependants
     * @param people
     * @return {*}
     */
    getStudentDependents: (people) =>
        people.filter((person) => dependantHelpers.isStudentDependant(person)),

    /**
     * Get all extended dependants
     * @param people
     */
    getExtendedDependents: (people) =>
        people.filter((person) => dependantHelpers.isExtendedDependant(person)),

    /**
     * Get the age of a person
     * @param dateOfBirth - Overrides person
     * @param person
     * @return {*}
     */
    getAge: (person, dateOfBirth) => {
        const dob = dateOfBirth
            ? moment(dateOfBirth, dateFormat)
            : moment(person?.Person?.BirthDate, dateFormat);
        return moment().diff(dob, 'years');
    },

    isBetweenAges: (age1, age2, dateOfBirth) => {
        const age = dependantHelpers.getAge(null, dateOfBirth);
        return age >= age1 && age <= age2;
    },

    isTurningAgeSoon: (person, age, birthDay) => {
        // If birthday is supplied, this overrides person
        const birthDate = birthDay
            ? moment(birthDay, dateFormat)
            : moment(person?.Person?.BirthDate, dateFormat);
        const today = moment();

        // The date at which this person will turn 'age' years old
        const turningAgeBirthDate = birthDate.add(age, 'years');

        // 'soon' means in the next 8 weeks
        const eightWeeksBeforeTurningAgeBirthDate = moment(
            turningAgeBirthDate,
        ).subtract(8, 'weeks');

        // Are we in the sweet spot?
        return today.isBetween(
            eightWeeksBeforeTurningAgeBirthDate,
            turningAgeBirthDate,
        );
    },

    /**
     * Determine is a single person is extended dep
     * 1. Has IsChildDependant === true
     * 2. Is between 21 and 31
     * @param person
     * @return {boolean}
     */
    isExtendedDependant: (person, dependantEndAge = 31) => {
        const age = dependantHelpers.getAge(person);

        return (
            person?.Person?.IsChildDependant === true && age > 21 && age < dependantEndAge
        );
    },

    isPendingDependant: (person) => {
        const stu = dependantHelpers.isPendingStudentDependant(person);
        const ext = dependantHelpers.isPendingExtendedDependant(person);
        return stu || ext;
    },

    isPendingStudentDependant: (person) => {
        return person?.PendingStudent === true;
    },

    isPendingExtendedDependant: (person) => {
        return person?.PendingDependant === true;
    },

    isAlreadyRegisteredDependant: (person) => {
        const stu = dependantHelpers.isPendingStudentDependant(person);
        const student = dependantHelpers.isStudentDependant(person);
        const ext = dependantHelpers.isPendingExtendedDependant(person);
        const extended = dependantHelpers.isExtendedDependant(person);

        return stu || student || ext || extended;
    },

    /**
     * Determine if a single person is student dep
     * 1. Has StudentInstitutionName
     * 2. Has StudentYear === current year
     * @param person
     */
    isStudentDependant: (person) => {
        const year = String(schoolYearHelpers.getCurrent());
        return (
            person?.Person?.StudentYear === year &&
            person?.Person?.StudentInstitutionName !== ''
        );
    },

    coverSupportsExtDep: (settings, product) => {
        const productCode = product?.productCode;
        const allowed = settings?.general?.dependant_allowed_skus;
        return allowed?.includes(productCode);
    },

    isMemberOrPartner: (person) => {
        if (!person?.RelationshipCode) {
            return false;
        }
        if (
            person.RelationshipCode === 'Ptnr' ||
            person.RelationshipCode === 'Sps' ||
            person.RelationshipCode === 'Membr'
        ) {
            return true;
        }
        return false;
    },
};
