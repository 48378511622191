import React from 'react';
import styled from 'styled-components';

const Label = styled.div`
    font-family: Lato;
    font-size: 11px;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #89889b;
    text-transform: uppercase;
`;

const Text = styled.div`
    font-family: Lato;
    font-size: 16px;
    color: #464b4e;
    line-height: 22px;
`;

const LabelledTextBlock = ({ label, text }) => {
    return (
        <div>
            <Label>{label}</Label>
            <Text className={'font-weight-regular'}>{text}</Text>
        </div>
    );
};

export default LabelledTextBlock;
