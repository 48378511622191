import actionTypes from './actions-types';

// // const structures = {
// //     item: {
// //         id: '2899283',
// //         personId: '142728',
// //         serviceTypeCode: '',
// //         hasSaved: true,
// //         provider: {},
// //         item: {},
// //         itemFee: '',
// //         itemBodyPart: '',
// //         dateOfService: '2020-12-12',
// //     }
// // }

// const TEST_ITEMS = [
//     // {
//     //     id: '92927834',
//     //     personId: '142728',
//     //     serviceId: '',
//     //     providerId: '',
//     //     itemId: '',
//     //     itemPrice: '35.00',
//     //     itemDescription: 'Adhesive Restoration',
//     //     dateOfService: '2020-12-12',
//     // },
//     {
//         id: '2899283',
//         personId: '142728',
//         serviceTypeCode: 'Den',
//         hasSaved: true,
//         provider: {
//             "providerid": "4457454K",
//             "title": "Dr",
//             "surname": "SONG",
//             "suburb": "FOREST LAKE",
//             "state": "QLD"
//         },
//         item: {
//             "ServiceClassCode": "D",
//             "ServiceCode": "338",
//             "EffectiveDate": "2002-03-01",
//             "Description": "Reduction of flabby ridge - per segment",
//             "IsBodyPartRequired": false
//         },
//         itemFee: '35.00',
//         itemBodyPart: '1',
//         dateOfService: '2020-12-12',
//     },
//     // {
//     //     id: '239472947834928374',
//     //     personId: '142728',
//     //     hasSaved: false,
//     //     serviceId: '',
//     //     providerId: '',
//     //     itemId: '',
//     //     itemPrice: '35.00',
//     //     itemDescription: 'Adhesive Restoration',
//     //     dateOfService: '2020-12-12',
//     // },
// ]

// const test_claim = {
//     "ClaimId": "12091732",
//     "MembershipId": "11007931",
//     "ReceivedDate": "2020-11-24",
//     "ClaimStatusCode": "Can",
//     "ClaimStatusEffectiveDate": "2020-11-24",
//     "ClaimLines": {
//         "ClaimLine": [
//             {
//                 "ClaimLineId": "1",
//                 "MembershipId": "11007931",
//                 "PersonId": "120709",
//                 "ReceivedDate": "2020-11-24T10:41:34.34",
//                 "ClaimLineStatus": "Reversed/Cancelled",
//                 "ClaimLineStatusEffectiveDate": "2020-11-24",
//                 "ServiceDate": "2020-11-07",
//                 "NumberOfServices": "1",
//                 "Fee": "120.00",
//                 "IsFeePaidByClaimant": true,
//                 "BenefitAmount": "0.00",
//                 "ProviderCode": "2344939X",
//                 "Service": {
//                     "ServiceClassCode": "D",
//                     "ServiceCode": "012",
//                     "EffectiveDate": "2006-04-01",
//                     "Description": "Periodic oral examination",
//                     "IsBodyPartRequired": false
//                 },
//                 "Message": {
//                     "MessageClassCode": "H",
//                     "MessageCode": "IPG  ",
//                     "Severity": "Error",
//                     "Text": "Service cannot be processed.  Refer Health Fund.",
//                     "ShortText": "Reasonability Rules"
//                 },
//                 "Person": {
//                     "GivenName": "Sarah",
//                     "Surname": "Abbot"
//                 },
//                 "isRejected": true,
//                 "ProviderName": "Dr LE-KIM"
//             },
//             {
//                 "ClaimLineId": "2",
//                 "MembershipId": "11007931",
//                 "PersonId": "120709",
//                 "ReceivedDate": "2020-11-24T10:41:34.34",
//                 "ClaimLineStatus": "Reversed/Cancelled",
//                 "ClaimLineStatusEffectiveDate": "2020-11-24",
//                 "ServiceDate": "2020-11-07",
//                 "NumberOfServices": "1",
//                 "Fee": "250.00",
//                 "IsFeePaidByClaimant": true,
//                 "BenefitAmount": "0.00",
//                 "ProviderCode": "0864863X",
//                 "Service": {
//                     "ServiceClassCode": "F",
//                     "ServiceCode": "FRAME",
//                     "EffectiveDate": "1981-09-01",
//                     "Description": "Optical Frames",
//                     "IsBodyPartRequired": false
//                 },
//                 "Message": {
//                     "MessageClassCode": "H",
//                     "MessageCode": "IPG  ",
//                     "Severity": "Error",
//                     "Text": "Service cannot be processed.  Refer Health Fund.",
//                     "ShortText": "Reasonability Rules"
//                 },
//                 "Person": {
//                     "GivenName": "Sarah",
//                     "Surname": "Abbot"
//                 },
//                 "isRejected": true,
//                 "ProviderName": "OPSM"
//             }
//         ]
//     }
// }

const INITIAL_STATE = {
    currentStep: '0',
    currentItemId: null,
    items: [],
    documents: [],
    sendConfirmation: false,
    email: '',
    claimAhb: false,
    eligibleForAhb: false,
    agreeDisclaimers: false,
    amountTotal: '',
    amountOutOfPocket: '',
    amountBenefit: '',
    useOneTimeBankAccount: false,
    oneTimeBankAccountDetails: {
        acc: '',
        bsb: '',
        name: '',
    },
    highRisk: false,
    // claimResult: test_claim
    benefitQuote: null,
    claimResult: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.SET_CLAIM_RESULTS:
            return {
                ...state,
                claimResult: action.payload,
            };

        case actionTypes.SET_CURRENT_ITEM:
            return {
                ...state,
                currentItemId: action.payload,
            };

        case actionTypes.SET_STEP:
            return {
                ...state,
                currentStep: action.payload,
            };

        case actionTypes.RESET:
            return INITIAL_STATE;

        // Item
        case actionTypes.ADD_ITEM:
            // Create a new item object with epoch id
            return {
                ...state,
                items: [
                    ...state.items,
                    {
                        id: action.payload,
                    },
                ],
            };

        case actionTypes.REMOVE_ITEM:
            // Find the item with matching ID
            return {
                ...state,
                items: state.items.filter((item) => item.id !== action.payload),
            };

        case actionTypes.UPDATE_ITEM: {
            // Find the correct item
            const targetItem = state.items.find(
                (item) => item.id === action.payload.itemId,
            );

            // Create new item with changes
            const updatedItem = {
                ...targetItem,
                ...action.payload.itemPartial,
            };

            return {
                ...state,
                // If this is the target item, replace with updated item.
                // If not, just return it.
                items: state.items.map((item) =>
                    item.id === action.payload.itemId ? updatedItem : item,
                ),
            };
        }
        // Document
        case actionTypes.ADD_DOCUMENT:
            return {
                ...state,
                documents: [...state.documents, action.payload],
            };

        case actionTypes.REMOVE_DOCUMENT:
            // Find the item with matching ID
            return {
                ...state,
                documents: state.documents.filter(
                    (document) => document.id !== action.payload,
                ),
            };

        // Review
        case actionTypes.SET_SEND_CONFIRMATION:
            return {
                ...state,
                sendConfirmation: action.payload,
            };

        case actionTypes.SET_ELIGIBLE_FOR_AHB:
            return {
                ...state,
                eligibleForAhb: action.payload,
            };

        case actionTypes.SET_CLAIM_ABH:
            return {
                ...state,
                claimAhb: action.payload,
            };

        case actionTypes.SET_AGREE_DISCLAIMERS:
            return {
                ...state,
                agreeDisclaimers: action.payload,
            };

        case actionTypes.SET_EMAIL:
            return {
                ...state,
                email: action.payload,
            };

        // Amount
        case actionTypes.SET_BENEFIT_QUOTE:
            return {
                ...state,
                benefitQuote: action.payload,
            };

        case actionTypes.SET_AMOUNT_OUT_OF_POCKET:
            return {
                ...state,
                amountOutOfPocket: action.payload,
            };

        case actionTypes.SET_AMOUNT_BENEFIT:
            return {
                ...state,
                amountBenefit: action.payload,
            };

        case actionTypes.SET_AMOUNT_TOTAL:
            return {
                ...state,
                amountTotal: action.payload,
            };

        // Bank Account
        case actionTypes.SET_USE_ONE_TIME_BANK_ACCOUNT:
            return {
                ...state,
                useOneTimeBankAccount: action.payload,
            };

        case actionTypes.SET_ONE_TIME_BANK_ACCOUNT_DETAILS:
            return {
                ...state,
                oneTimeBankAccountDetails: action.payload,
            };

        case actionTypes.SET_HIGH_RISK:
            return {
                ...state,
                highRisk: action.payload,
            };

        default:
            return state;
    }
};
