import React from 'react';
import { useSelector } from 'react-redux';
import AdBlock from 'components/sections/overview/cards/advert/components/AdBlock';
import OverviewCoverControlsSection from 'components/sections/overview/cards/OverviewCoverControlsSection';
import OverviewAppAdvertSection from 'components/sections/overview/cards/advert/OverviewAppAdvertSection';
import OverviewCoverHospitalSection from 'components/sections/overview/cards/OverviewCoverHospitalSection';
import OverviewCoverExtrasSection from 'components/sections/overview/cards/OverviewCoverExtrasSection';
import UserSwitcher from 'components/elements/etc/UserSwitcher';
import HRAModal from 'components/modals/HRAModal/HRAModal';
import MembershipMessages from 'components/elements/banners/MembershipMessages';
import { StoreType } from 'store/types';

const OverviewPage = () => {
    const product = useSelector((state: StoreType) => state.product);

    function hasExtras(product) {
        if (!product?.base?.type) {
            return;
        }

        if (
            product.base.type === 'extras' ||
            product.base.type === 'bundle' ||
            product.base.type === 'package'
        ) {
            return true;
        }
        return false;
    }

    function hasHospital(product) {
        if (!product?.base?.type) {
            return;
        }

        if (
            product.base.type === 'hospital' ||
            product.base.type === 'bundle' ||
            product.base.type === 'package'
        ) {
            return true;
        }
        return false;
    }

    const showExtras = hasExtras(product);
    const showHospital = hasHospital(product);

    const hospitalClasses =
        (showHospital === true && showExtras === true) ||
        (showHospital === false && showExtras === true)
            ? 'col-md-12 mt-lg-0 mb-lg-4 mt-sm-0 col-lg-4'
            : 'col-md-12 mt-lg-0 mt-sm-4 col-lg-8';
    const extrasClasses =
        (showExtras === true && showHospital === true) ||
        (showExtras === true && showHospital === false)
            ? 'col-md-12 mt-lg-0 mt-sm-4 col-lg-8'
            : 'col-md-12 mt-lg-0 mb-lg-4 mt-sm-0 col-lg-4';

    return (
        <div>
            <UserSwitcher />
            <main className="container-fluid p-4">
                <MembershipMessages />

                <div className="row">
                    <section className="col-md-12 col-lg-5">
                        <OverviewCoverControlsSection />
                    </section>

                    <OverviewAppAdvertSection>
                        <AdBlock
                            type="ad_block"
                            gtag={{
                                action: 'adblock_learnmore',
                                label: 'Clicked on Adblock CTA button',
                            }}
                        />
                        <AdBlock
                            internal
                            type="refer_block"
                            linkTo="/member/refer"
                            gtag={{
                                action: 'promo_referfriend',
                                label: 'Clicked on Refer a Friend CTA button',
                            }}
                        />
                    </OverviewAppAdvertSection>
                </div>

                <div className="row">
                    <section className={hospitalClasses}>
                        <OverviewCoverHospitalSection />
                    </section>

                    <section className={extrasClasses}>
                        <OverviewCoverExtrasSection />
                    </section>
                </div>
            </main>
            <HRAModal align={'center'} />
        </div>
    );
};

export default OverviewPage;
