import React from 'react';
import styled from 'styled-components';

const TableHeader = styled.div`
    font-family: Lato;
    font-size: 0.8rem;
    font-weight: 500;
    color: #89889b;
    text-transform: uppercase;
`;

const Label = ({ text }) => {
    return <TableHeader>{text}</TableHeader>;
};

export default Label;
