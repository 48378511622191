import React from 'react';
import Visa from 'img/vector/Visa/comp';
import Mastercard from 'img/vector/Mastercard/comp';

const CardPicture = ({ type, style }) => {
    if (type === 'Mastercard') return <Mastercard style={style} />;
    if (type === 'Visa') return <Visa style={style} />;
    return null;
};

export default CardPicture;
