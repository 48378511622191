import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Table } from 'react-bootstrap';
import { fastClaimActions } from 'store';
import { FaPlusCircle, HiPencil } from 'react-icons/all';
import {
    ymdToSlashFormatDate,
    getSelectedUser,
    allPersons,
} from 'libs/helpers';
import gtagEvent from 'libs/gtagEvent';
import AddClaimItemModal from 'components/modals/ClaimItemModals/AddClaimItemModal/AddClaimItemModal';
import EditClaimItemModal from 'components/modals/ClaimItemModals/EditClaimItemModal/EditClaimItemModal';

const Headers = [
    'SERVICE DATE',
    'CLAIMANT',
    'SERVICE',
    'PROVIDER',
    'ITEM',
    'FEE',
    '',
];

const StyledTable = styled(Table)`
    /* td:not(:first-child),
    th:not(:first-child) {
        text-align: right;
    } */
`;

const LineItem = ({ items, persons, setEditClaimItemModalVisible }) => {
    const dispatch = useDispatch();

    return (
        <>
            {items.length > 0
                ? items.map((item, idx) => {
                      const linePerson = getSelectedUser(
                          persons,
                          item.personId,
                      );
                      return (
                          <tr key={idx}>
                              <td>
                                  {ymdToSlashFormatDate(item.dateOfService)}
                              </td>
                              <td>
                                  {linePerson?.Person?.GivenName}{' '}
                                  {linePerson?.Person?.Surname}
                              </td>
                              <td>{item?.Description}</td>
                              <td>
                                  {item.provider.title} {item.provider.surname}{' '}
                                  - {item.provider.providerid}
                              </td>
                              <td>
                                  {item.item.ServiceCode} -{' '}
                                  {item.item.Description}
                              </td>
                              <td>${parseFloat(item.itemFee).toFixed(2)}</td>
                              <td>
                                  <Button
                                      variant="link"
                                      style={{
                                          padding: '0px',
                                      }}
                                      className="text-uppercase text-primary"
                                      onClick={() => {
                                            gtagEvent({
                                                screen: 'fastclaim',
                                                action: 'edititem',
                                                label: 'clicked edit item button in fast claim',
                                                type: 'modal_open',
                                            });
                                          dispatch(
                                              fastClaimActions.item.set.currentItem(
                                                  item.id,
                                              ),
                                          );
                                          setEditClaimItemModalVisible(true);
                                      }}>
                                      <HiPencil
                                          style={{ verticalAlign: 'middle' }}
                                      />{' '}
                                      Edit
                                  </Button>
                              </td>
                          </tr>
                      );
                  })
                : null}
        </>
    );
};

const ClaimDetails = () => {
    const membership = useSelector((state) => state.membership);
    const specialties = useSelector((state) => state.claimSpeciality);
    const persons = allPersons(membership);
    const fastClaimStore = useSelector((state) => state.fastClaim);
    const dispatch = useDispatch();

    const [addClaimItemModalVisible, setAddClaimItemModalVisible] =
        useState(false);
    const [editClaimItemModalVisible, setEditClaimItemModalVisible] =
        useState(false);

    function changeSection() {
        dispatch(fastClaimActions.general.setStep('1'));
    }

    useEffect(() => {
        if (fastClaimStore.items.length > 0) {
            let totalAmount = 0;
            fastClaimStore.items.forEach((item) => {
                totalAmount = totalAmount + parseFloat(item.itemFee);
            });
            if (fastClaimStore.amountTotal !== totalAmount) {
                dispatch(fastClaimActions.amount.set.total(totalAmount));
            }
        } else {
            if (fastClaimStore.amountTotal !== '') {
                dispatch(fastClaimActions.amount.set.total(''));
            }
        }
    }, [fastClaimStore]);

    return (
        <>
            <div className="row claim-details-section">
                <StyledTable className="" responsive striped>
                    <thead>
                        <tr>
                            {Headers.map((head) => (
                                <th key={head}>{head}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <LineItem
                            items={fastClaimStore.items}
                            specialties={specialties}
                            persons={persons}
                            setEditClaimItemModalVisible={
                                setEditClaimItemModalVisible
                            }
                        />
                        {fastClaimStore.amountTotal !== '' ? (
                            <>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td
                                        className="font-weight-medium"
                                        style={{ fontSize: '16px' }}>
                                        $
                                        {parseFloat(
                                            fastClaimStore.amountTotal,
                                        ).toFixed(2)}
                                    </td>
                                    <td></td>
                                </tr>
                            </>
                        ) : null}
                    </tbody>
                </StyledTable>
            </div>
            <div className="row justify-content-between pb-5 pt-3">
                <div className="col">
                    <Button
                        variant="link"
                        style={{
                            padding: '9px',
                        }}
                        className="text-uppercase text-primary-dark-1"
                        onClick={() => {
                            gtagEvent({
                                screen: 'fastclaim',
                                action: 'additem',
                                label: 'clicked add item button in fast claim',
                                type: 'modal_open',
                            });
                            setAddClaimItemModalVisible(true);
                        }}>
                        <FaPlusCircle style={{ verticalAlign: 'middle' }} /> Add
                        item
                    </Button>
                </div>
                <div className="col text-right">
                    <Button
                        variant="secondary"
                        className=""
                        onClick={() => {
                            gtagEvent({
                                screen: 'fastclaim',
                                action: 'one_next',
                                label: 'clicked next button in step one of fast claim',
                            });
                            changeSection();
                        }}
                        disabled={fastClaimStore.items.length === 0}>
                        Next
                    </Button>
                </div>
            </div>
            <AddClaimItemModal
                handleClose={() => {
                    gtagEvent({
                        screen: 'editclaimitem',
                        action: 'cancel',
                        label: 'closed or cancelled modal',
                        type: 'modal',
                    });
                    setAddClaimItemModalVisible(false);
                }}
                show={addClaimItemModalVisible}
                align={'right'}
            />
            <EditClaimItemModal
                handleClose={() => {
                    gtagEvent({
                        screen: 'editclaimitem',
                        action: 'cancel',
                        label: 'closed or cancelled modal',
                        type: 'modal',
                    });
                    setEditClaimItemModalVisible(false);
                }}
                show={editClaimItemModalVisible}
                align={'right'}
            />
        </>
    );
};

export default ClaimDetails;
