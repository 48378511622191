import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';

export const EmailCapture = ({ loading }) => {
    const { touched, errors, getFieldProps } = useFormikContext();
    return (
        <div className="d-flex pb-3 pt-3 flex-wrap modal-box-shadow">
            <Form.Group controlId={`email`} className="col-12 text-label-gray">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="email"
                    className={
                        errors.email &&
                        touched.email &&
                        !loading &&
                        'is-invalid'
                    }
                    {...getFieldProps('email')}
                />
                {touched.email && errors.email && !loading && (
                    <Form.Control.Feedback type={'invalid'}>
                        {errors.email}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
        </div>
    );
};

export default EmailCapture;
