import RebateFormActionTypes from './actions-types';

const INITIAL_STATE = {
    show: false,
    step: 1,
    tier: null,
    html: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RebateFormActionTypes.SET_REBATE_FORM_SHOW:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(
                    `SET_REBATE_FORM_SHOW with payload ${action.payload}`,
                );
            }
            return { ...state, show: action.payload?.show };
        case RebateFormActionTypes.SET_REBATE_FORM_STEP:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(
                    `SET_REBATE_FORM_STEP with payload ${action.payload}`,
                );
            }
            return { ...state, step: action.payload };
        case RebateFormActionTypes.SET_REBATE_FORM_TIER:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(
                    `SET_REBATE_FORM_TIER with payload ${action.payload}`,
                );
            }
            return { ...state, tier: action.payload };
        case RebateFormActionTypes.SET_REBATE_FORM_START_DATE:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(
                    `SET_REBATE_FORM_START_DATE with payload ${action.payload}`,
                );
            }
            return { ...state, start_date: action.payload };
        case RebateFormActionTypes.SET_REBATE_FORM_HTML:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(
                    `SET_REBATE_FORM_HTML with payload ${action.payload}`,
                );
            }
            return { ...state, html: action.payload };
        case RebateFormActionTypes.SET_REBATE_FORM_RESET:
            return INITIAL_STATE;
        default:
            return state;
    }
};
