import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';
import CustomModal from 'components/modals/Modal';
import { Formik } from 'formik';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { CONFIG } from 'libs/constants';
import { dependantHelpers } from 'libs/dependant';
import gtagEvent from 'libs/gtagEvent';
import { allPersons, getSelectedUser } from 'libs/helpers';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { setMembership } from 'store';
import api from '@qldtuh/tuh-uh-js-api';
import {
    MustTakeCover,
    MedicareCardInfo,
    EmailCapture,
} from '../Shared/components';
import { getEditPersonDefaults } from '../Shared/helpers';
import { EditPersonModalSchema } from '../Shared/validation/schema';
import {
    constructSubmittable,
    determineIsDependentAge,
} from '../Shared/helpers/index';
import {
    DependentsInfo,
    JoiningInfo,
    MemberInfo,
    PreviousFund,
    SubmitForm,
} from '../Shared/sections';

const EditPersonModal = ({ show, handleClose, align }) => {
    const selectedUserId = useSelector((state) => state.selectedUser);
    const membership = useSelector((state) => state.membership);
    const dependantAge = useSelector((state) => state.settings.general.dependant_age);

    const dispatch = useDispatch();

    const USERS = allPersons(membership);
    const currentUser = getSelectedUser(USERS, selectedUserId);

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [outcome, setOutcome] = useState(null);

    const INITIAL_VALUES = getEditPersonDefaults(selectedUserId, currentUser);
    const defaultValues = INITIAL_VALUES;

    const isMainMember = currentUser?.RelationshipCode === 'Membr';
    const isMemberOrPartner = dependantHelpers.isMemberOrPartner(currentUser);
    const isDependantAgeDetermine = determineIsDependentAge(currentUser, dependantAge);

    const isDependantAge = isMemberOrPartner ? false : isDependantAgeDetermine;

    const doSuccess = (response) => {
        if (response?.status === 'error') {
            throw new Error(response.message);
        } else {
            console.log(response);
            dispatch(setMembership(response));
            setLoading(false);
            setMessage('Successfully updated person');
            setOutcome('success');
            setTimeout(() => {
                setOutcome(null);
                setMessage('');
            }, 10000);
        }
    };

    const doError = (err) => {
        console.log('ERROR');
        setOutcome('fail');
        setMessage(err.message || 'Could not connect to server');
        setTimeout(() => {
            setOutcome(null);
            setMessage('');
        }, 10000);
        setLoading(false);
    };

    const onSubmit = async (values) => {
        setLoading(true);
        gtagEvent({
            screen: 'editperson',
            action: 'submit',
            label: 'Requested to edit person on cover',
            type: 'modal',
        });

        let outValues = constructSubmittable.edit(values);
        console.log('SUBMIT: ', outValues);

        const access_token = getAccessToken();
        const memberid = getMembershipId();

        api.user(CONFIG, access_token)
            .person.editPerson(memberid, outValues)
            .then(doSuccess)
            .catch(doError);
    };

    return (
        <CustomModal
            handleClose={handleClose}
            align={align}
            show={show}
            title={'Edit Person Covered'}>
            <div className={'font-feature row row-cols-1'}>
                <Formik
                    enableReinitialize={true}
                    validateOnChange={true}
                    validateOnBlur={true}
                    validateOnMount={true}
                    validationSchema={EditPersonModalSchema}
                    initialValues={defaultValues || null}
                    onSubmit={(values, actions) => onSubmit(values, actions)}>
                    {({ values }) => (
                        <Form>
                            <JoiningInfo loading={loading} edit />
                            <MemberInfo loading={loading} edit />
                            {isMainMember && (
                                <MedicareCardInfo loading={loading} />
                            )}
                            <EmailCapture loading={loading} />
                            {isDependantAge &&
                                !values.isAlreadyRegisteredDependant && (
                                    <DependentsInfo loading={loading} edit />
                                )}
                            <PreviousFund edit />
                            <MustTakeCover />
                            <SubmitForm loading={loading} edit />
                        </Form>
                    )}
                </Formik>
                <Fade bottom when={outcome !== null}>
                    <StatusNotificationCard
                        status={outcome === 'fail' ? 'error' : 'success'}>
                        {message}
                    </StatusNotificationCard>
                </Fade>
            </div>
        </CustomModal>
    );
};

export default EditPersonModal;
