import React from 'react';
import { Header, HeroImage, HeroTitle } from '../../PromoBlock/exports';
import ShareCode from 'img/raster/ShareCode/comp';

const ReferFriendHead = () => {
    return (
        <Header>
            <HeroTitle>
                <h1>Tell a mate!</h1>
                <h3>
                    Tell your family, friends, and workmates about us and, if
                    they join , you&apos;ll receive a $100 eGift Card*
                </h3>
            </HeroTitle>
            <HeroImage
                image={<ShareCode classes="hero-image align-middle" />}
            />
        </Header>
    );
};

export default ReferFriendHead;
