import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';

import ProviderSearchBox from 'components/sections/providers/ProviderSearchBox';

// const markers = [
//     {
//         type: 'specialistDental',
//         name: 'Your local Specialist Dentist',
//         location: {
//             lat: 34.30,
//             lng: 34.20,
//         },
//         features: {
//             electronicClaiming: true,
//             recommended: true,
//             userFavourite: true,
//         },
//     },
//     {
//         type: 'optical',
//         name: 'Your Local Optician',
//         location: {
//             lat: 34.30,
//             lng: 34.20,
//         },
//         features: {
//             electronicClaiming: true,
//             recommended: true,
//             userFavourite: true,
//         },
//     },
//     {
//         type: 'dentist',
//         name: 'Your Local Dentist',
//         location: {
//             lat: 34.30,
//             lng: 34.20,
//         },
//         features: {
//             electronicClaiming: true,
//             recommended: true,
//             userFavourite: true,
//         },
//     },
//     {
//         type: 'gp',
//         name: 'Your Local GP',
//         location: {
//             lat: 34.30,
//             lng: 34.20,
//         },
//         features: {
//             electronicClaiming: true,
//             recommended: true,
//             userFavourite: true,
//         },
//     },
//     {
//         type: 'prosthesis',
//         name: 'Prosthetics Specialist',
//         location: {
//             lat: 34.30,
//             lng: 34.20,
//         },
//         features: {
//             electronicClaiming: true,
//             recommended: true,
//             userFavourite: true,
//         },
//     },
//     {
//         type: 'other',
//         name: 'Ancillary Services QLD',
//         location: {
//             lat: 34.30,
//             lng: 34.20,
//         },
//         features: {
//             electronicClaiming: true,
//             recommended: true,
//             userFavourite: true,
//         },
//     },
//     {
//         type: 'alternative',
//         name: 'Alternative Therapie QLD',
//         location: {
//             lat: 34.30,
//             lng: 34.20,
//         },
//         features: {
//             electronicClaiming: true,
//             recommended: true,
//             userFavourite: true,
//         },
//     },
//     {
//         type: 'therapist',
//         name: 'Your local Therapist',
//         location: {
//             lat: 34.30,
//             lng: 34.20,
//         },
//         features: {
//             electronicClaiming: true,
//             recommended: true,
//             userFavourite: true,
//         },
//     },
// ]

const ProviderPage = () => {
    // Here will provide a default map position
    // TODO: Where should this location be?
    const [lat, setLat] = useState(59.95);
    const [lng, setLng] = useState(30.33);
    console.log('Unused Coords: ', { lat, lng });

    // Trigger geolocation prompt
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
        });
    }

    return (
        <main className="h-100 position-relative">
            <GoogleMapReact
                // bootstrapURLKeys={{ key: 'AIzaSyBFHZsQxb_FG_Ym6K5267eFTVlX_zj9Gck' }}
                defaultCenter={{
                    lat: -27.467778,
                    lng: 153.028056,
                }}
                center={{
                    lat: -27.467778,
                    lng: 153.028056,
                }}
                defaultZoom={13}
                yesIWantToUseGoogleMapApiInternals={true}>
                {/* Example map marker */}
                {/* We can use any react comp here */}
                {/*<Button
                    lat={-27.467778}
                    lng={153.028056}
                    text="My Marker!"
                />*/}
            </GoogleMapReact>

            <ProviderSearchBox />
        </main>
    );
};

export default ProviderPage;
