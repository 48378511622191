import React from 'react';

import ContactUsCard from 'components/sections/contact/ContactUsCard';
import EnquireNowCard from 'components/sections/contact/EnquireNowCard';

const ContactPage: React.FC = () => {
    return (
        <main className="container-fluid p-4">
            <div className="row">
                <div className="col-md-12 col-lg-7">
                    <div>
                        <ContactUsCard />
                    </div>
                </div>
                <div className="col-md-12 col-lg-5">
                    <div>
                        <EnquireNowCard />
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ContactPage;
