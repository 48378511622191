import React from 'react';
import ReactDOM from 'react-dom';
import {Helmet} from 'react-helmet';
import {IconContext} from 'react-icons';

import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore, compose} from 'redux';
import thunk from 'redux-thunk';

import App from './router';
import reducers from './store';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import './styles/bootstrap.scss';

let store;
if (process.env.NODE_ENV!=='production') {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    store = createStore(
        reducers,
        composeEnhancers(applyMiddleware(thunk))
    );
} else {
    store = createStore(
        reducers,
        applyMiddleware(thunk)
    );
}

Bugsnag.start({
  apiKey: 'fd3c95808d23e6d65e1e2db90fc9ac0d',
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

if (process.env.NODE_ENV==='production') {
    console.log = function () {};
}

// const gtagId = 'G-VJ452D0KWQ';

ReactDOM.render(
    <ErrorBoundary>
        <Helmet>
            <meta charSet="utf-8"/>
            <link rel="icon" href="/favicon.ico"/>
            <title>UH | Union Health | Member Portal</title>
            <link
                href="https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap"
                rel="stylesheet"
            />
            <script src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`}></script>

            <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG_ID}`}></script>
            <script>
                {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments)}
                gtag('js', new Date());
                gtag('config', '${process.env.REACT_APP_GTAG_ID}');`}
            </script>
        </Helmet>
        <IconContext.Provider value={{style: {verticalAlign: 'bottom'}}}>
            <Provider store={store}>
                <Router>
                    <App/>
                </Router>
            </Provider>
        </IconContext.Provider>
    </ErrorBoundary>,
    document.getElementById('root'),
);
