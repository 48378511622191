export const generatePayload = (data) => {
    let payload = {};
    payload.email = data.email;
    payload.consentemail = data.consentEmail;
    payload.partnerauth = data.partnerAuth;

    payload.addresses = {};
    payload.addresses.home = {};
    payload.addresses.home.Line1 = data.homeLine1;
    if (data.homeLine2 !== '') {
        payload.addresses.home.Line2 = data.homeLine2;
    }
    payload.addresses.home.Suburb = data.homeSuburb;
    payload.addresses.home.State = data.homeState;
    payload.addresses.home.PostCode = data.homePostcode;

    if (data.homeSame) {
        payload.addresses.post = payload.addresses.home;
    } else {
        payload.addresses.post = {};
        payload.addresses.post.Line1 = data.postLine1;
        if (data.postLine2 !== '') {
            payload.addresses.post.Line2 = data.postLine2;
        }
        payload.addresses.post.Suburb = data.postSuburb;
        payload.addresses.post.State = data.postState;
        payload.addresses.post.PostCode = data.postPostcode;
    }

    const phones = {};
    data.mobile && (phones.mobile = data.mobile);
    data.mobile2 && (phones.mobile2 = data.mobile2);
    data.homePhone && (phones.home = data.homePhone);
    data.workPhone && (phones.work = data.workPhone);

    payload.phones = phones;
    return payload;
};
