import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { isActiveCampaign, extractConfig } from '../functions/exports';
import { Link } from 'react-router-dom';
import gtagEvent from 'libs/gtagEvent';
import { StoreType } from 'store/types';

interface AdBlockProps {
    type: 'ad_block' | 'refer_block' | string;
    linkTo?: string;
    internal?: boolean;
    gtag: {
        screen?: string | null,
        action: string | null,
        label: string | null,
    }
}

const AdBlock: React.FC<AdBlockProps> = ({
    type,
    linkTo,
    internal = false,
    gtag = { screen: null, action: null, label: null },
}) => {
    const configSettings = useSelector((state: StoreType) => state.settings);
    const [bgColour, setBgColour] = useState('#fff');
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState('_self');
    /**
     * NOTE: You can destructure legacy props from extractConfig()
     * LEGACY PROPS: extra_img1, extra_img1_url, extra_img2, extra_img2_url
     * these original held external links to android/ios apps but no longer see use
     * extra_img1_url now holds the link to member page
     */
    const {
        bg_color: color,
        show: active = 1,
        start_date: start,
        end_date: end,
        extra_img1_url: url,
        btn_label: buttonText = 'Learn more',
        image,
        text,
    } = extractConfig(configSettings, type);

    useEffect(() => {
        setBgColour(color);
        setShow(isActiveCampaign(active, start, end));
        if (url) setTarget(url.includes('http') ? '_blank' : '_self');
        if (linkTo) setTarget(linkTo.includes('http') ? '_blank' : '_self');
    }, [configSettings]);
    const eventScreen = gtag.screen ?? 'overview';
    const eventAction = gtag.action ?? 'advert_promo';
    const eventLabel =
        gtag.label ?? 'Clicked Advert or Promo learn more button.';
    return (
        <Card
            className="overview-ad-block"
            style={{
                backgroundColor: bgColour,
                display: show ? 'block' : 'none',
            }}>
            <div className="row h-100 px-3">
                <div className={`col-${linkTo ? '9' : '8'} align-self-center`}>
                    <div className={`text ${linkTo ? 'promo' : ''}`}>
                        <ReactMarkdown>{text}</ReactMarkdown>
                        {linkTo && (
                            <small className={'terms-and'}>
                                *Terms and conditions apply
                            </small>
                        )}
                    </div>
                    <div
                        className={'button-holder'}
                        onClick={() => {
                            gtagEvent({
                                screen: eventScreen,
                                action: eventAction,
                                label: eventLabel,
                            });
                        }}>
                        {url && (
                            <a
                                href={url}
                                target={target}
                                rel="noopener noreferrer"
                                className="btn btn-secondary">
                                {buttonText}
                            </a>
                        )}
                        {linkTo &&
                            (internal ? (
                                <Link to={linkTo} className="btn btn-new-claim">
                                    {buttonText}
                                </Link>
                            ) : (
                                <a
                                    href={linkTo}
                                    target={target}
                                    rel="noopener noreferrer"
                                    className="btn btn-new-claim">
                                    {buttonText}
                                </a>
                            ))}
                    </div>
                </div>
                <div className={`col-${linkTo ? '3' : '4'} align-self-end`}>
                    {image && (
                        <img
                            src={image}
                            alt="ad image"
                            className="img-fluid ad-block-img"
                        />
                    )}
                </div>
            </div>
        </Card>
    );
};

export default AdBlock;
