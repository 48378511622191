import React, { useEffect, useState } from 'react';
import { WarningMessage } from './WarningMessage';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

export const MustRegisterDependent = () => {
    const { values, setFieldValue } = useFormikContext();
    const dependantEndAge = useSelector((state) => state.settings.general.dependant_age);
    const dependantURL = useSelector((state) => state.settings.general.ex_dependant_url);

    const [show, setShow] = useState(false);

    useEffect(() => {
        if (['Ptnr', 'Sps', 'Membr'].includes(values.relationship)) {
            setFieldValue('disablesubmit', false);
            setShow(false);
        } else {
            const age = moment().diff(values.dob, 'years');
            if (age >= 21 && age < parseInt(dependantEndAge)) {
                if (!(values.schooldeclare || values.registerAsExtDep)) {
                    setFieldValue('disablesubmit', true);
                    setShow(true);
                } else {
                    setFieldValue('disablesubmit', false);
                    setShow(false);
                }
            } else {
                setFieldValue('disablesubmit', false);
                setShow(false);
            }
        }
    }, [values]);

    function ordinal_suffix_of(iii) {
        var jjj = iii % 10,
            kkk = iii % 100;
        if (jjj == 1 && kkk != 11) {
            return iii + "st";
        }
        if (jjj == 2 && kkk != 12) {
            return iii + "nd";
        }
        if (jjj == 3 && kkk != 13) {
            return iii + "rd";
        }
        return iii + "th";
    }

    return (
        <>
            {show && (
                <>
                    <WarningMessage
                        title="This person can register as a dependant (conditions apply) or take out their own cover"
                        buttonText="Find out more"
                        buttonOnclick={() => {
                            window.open(
                                dependantURL,
                                '_blank',
                            ); //to open new page
                        }}>
                        <div>
                            A dependent over the age of 21, 
                            but before the eve of their {ordinal_suffix_of(parseInt(dependantEndAge))} birthday 
                            can register as a student dependant (conditions apply), 
                            or an extended dependent (on eligible covers). Alternatively if this
                            person is interested in taking out cover, they can
                            review our options{' '}
                            <a
                                href={dependantURL}
                                target="_blank"
                                rel="noopener noreferrer">
                                here
                            </a>{' '}
                            or contact us on 1300 661 283. We can also arrange a
                            call back if you provide us with this person’s email
                            address and phone number by clicking the button to
                            discuss cover options.
                        </div>
                    </WarningMessage>
                </>
            )}
        </>
    );
};

export default MustRegisterDependent;
