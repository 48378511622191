import CardHorizontalRule from 'components/elements/divisions/CardHorizontalRule';
import { Formik } from 'formik';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { CONFIG } from 'libs/constants';
import gtagEvent from 'libs/gtagEvent';
import {
    allAdults,
    getAddressByType,
    getPhoneByName,
    mainPerson,
} from 'libs/helpers';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { IoMdInformationCircle } from 'react-icons/all';
import { useSelector } from 'react-redux';
import Fade from 'react-reveal/Fade';
import api from '@qldtuh/tuh-uh-js-api';
import * as Yup from 'yup';
import { StoreType } from 'store/types';

const EnquireNowCard = () => {
    const membership = useSelector((state: StoreType) => state.membership);
    const adults = allAdults(membership);
    const [loading, setLoading] = useState(false);

    const [errorSpace, setErrorSpace] = useState('');
    const [failedAttempts, setFailedAttempts] = useState(0);

    const [successSpace, setSuccessSpace] = useState('');
    const [successAttempts, setSuccessAttempts] = useState(0);

    const getDefaultValues = () => {
        return {
            person: mainPerson(membership)?.Person?.PersonId || '',
            timePreferred: '',
            message: '',
        };
    };

    const [defaultValues, setDefaultValues] = useState(getDefaultValues());

    useEffect(() => {
        if (membership) {
            setDefaultValues(getDefaultValues());
        }
    }, [membership]);

    const validationSchemaContact = Yup.object().shape({
        timePreferred: Yup.string().required('Time is required'),
        message: Yup.string()
            .min(1, 'Message is required')
            .required('Message is required'),
    });

    const onSubmit = async (values, actions, adults, membership) => {
        setLoading(true);
        gtagEvent({
            screen: 'contact',
            action: 'sendmessage',
            label: 'Sent message from contact page',
        });
        // console.log('VALUES: ',values);

        const sendingUser = adults.find(
            (person) => person.Person.PersonId === values.person,
        );
        // console.log('PERSON: ',sendingUser);

        const homeAddress = getAddressByType(membership, 'Home');
        const mobileNumber = getPhoneByName(membership, 'Mobile1');

        const out = {
            memberid: membership.MembershipId,
            first_name: sendingUser.Person.GivenName,
            last_name: sendingUser.Person.Surname,
            email: membership?.EmailAddresses?.EmailAddress?.[0]?.EmailAddress ?? '',
            mobile: mobileNumber ?? '',
            contact_time: values.timePreferred,
            state: homeAddress.StateCode,
            message: values.message,
        };

        const access_token = getAccessToken();
        const memberid = getMembershipId();

        try {
            if (!memberid) {
                throw new Error('No member id found');
            }
            const response = await api
                .website(CONFIG, access_token)
                .contact(memberid, out);

            if (response?.status == 'error') {
                throw new Error(response.message);
            } else {
                console.log(response);
                actions.resetForm({});
                setSuccessSpace('Thank you for your enquiry');
                setSuccessAttempts(1);
                setTimeout(() => {
                    setSuccessSpace('');
                    setSuccessAttempts(0);
                }, 10000);
                setLoading(false);
            }
        } catch (err) {
            if (err instanceof Error) {
                console.log('ERROR');
                console.log(err.message);
                setErrorSpace(err.message);
            }
            setFailedAttempts(1);
            setTimeout(() => {
                setErrorSpace('');
                setFailedAttempts(0);
            }, 10000);
            setLoading(false);
        }

        // setLoading(false);
    };

    return (
        <>
            <Card className="pt-4 pb-2 h-100">
                <h1 className="px-4 text-primary text-uppercase mx-1">
                    Enquire Now
                </h1>
                <CardHorizontalRule />

                <div className="px-4 py-3" style={{ fontSize: '14px' }}>
                    Complete the short form below and we&apos;ll call you back
                    at your convenience to discuss your options.
                </div>
                <CardHorizontalRule />

                <div className="px-2 py-3">
                    <Formik
                        enableReinitialize={true}
                        validateOnChange={true}
                        validateOnBlur={true}
                        validateOnMount={true}
                        initialValues={defaultValues || null}
                        validationSchema={validationSchemaContact}
                        onSubmit={(values, actions) =>
                            onSubmit(values, actions, adults, membership)
                        }>
                        {(props) => {
                            const {
                                touched,
                                errors,
                                handleSubmit,
                                isValid,
                                getFieldProps,
                            } = props;
                            return (
                                <Form>
                                    <Form.Group
                                        controlId={`from`}
                                        className="col-12 text-label-gray">
                                        <Form.Label
                                            style={{ fontSize: '11px' }}>
                                            From
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            className={
                                                errors.person &&
                                                touched.person &&
                                                !loading
                                                    ? 'is-invalid'
                                                    : ''
                                            }
                                            {...getFieldProps('person')}>
                                            {adults
                                                ? adults.map((person) => (
                                                      <option
                                                          value={
                                                              person?.Person
                                                                  ?.PersonId
                                                          }
                                                          key={
                                                              person?.Person
                                                                  ?.PersonId
                                                          }>{`${person.Person.GivenName} ${person.Person.Surname}`}</option>
                                                  ))
                                                : ''}
                                        </Form.Control>
                                        {touched.person &&
                                        errors.person &&
                                        !loading ? (
                                            <Form.Control.Feedback
                                                type={'invalid'}>
                                                {errors.person}
                                            </Form.Control.Feedback>
                                        ) : null}
                                    </Form.Group>
                                    <Form.Group
                                        controlId={`preferred-time`}
                                        className="col-12 text-label-gray">
                                        <Form.Label
                                            style={{ fontSize: '11px' }}>
                                            Preferred contact time
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            className={
                                                errors.timePreferred &&
                                                touched.timePreferred &&
                                                !loading
                                                    ? 'is-invalid'
                                                    : ''
                                            }
                                            {...getFieldProps('timePreferred')}>
                                            <option value="">
                                                - Please select time -
                                            </option>
                                            <option value="morning">
                                                Morning
                                            </option>
                                            <option value="afternoon">
                                                Afternoon
                                            </option>
                                        </Form.Control>
                                        {touched.timePreferred &&
                                        errors.timePreferred &&
                                        !loading ? (
                                            <Form.Control.Feedback
                                                type={'invalid'}>
                                                {errors.timePreferred}
                                            </Form.Control.Feedback>
                                        ) : null}
                                    </Form.Group>
                                    <Form.Group
                                        controlId={`message`}
                                        className="col-12 text-label-gray">
                                        <Form.Label
                                            style={{ fontSize: '11px' }}>
                                            Message
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={5}
                                            placeholder="Enter your enquiry"
                                            className={
                                                errors.message &&
                                                touched.message &&
                                                !loading
                                                    ? 'is-invalid'
                                                    : ''
                                            }
                                            {...getFieldProps(
                                                'message',
                                            )}></Form.Control>
                                        {touched.message &&
                                        errors.message &&
                                        !loading ? (
                                            <Form.Control.Feedback
                                                type={'invalid'}>
                                                {errors.message}
                                            </Form.Control.Feedback>
                                        ) : null}
                                    </Form.Group>
                                    <div className="py-3 px-3">
                                        <IoMdInformationCircle
                                            style={{ verticalAlign: 'middle' }}
                                            size={20}
                                            className="text-primary"
                                        />{' '}
                                        <span style={{ fontSize: '12px' }}>
                                            Your information will only be used
                                            as per our{' '}
                                            <a
                                                href="https://unionhealth.com.au/privacy-and-fraud-statement"
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                Privacy Policy.
                                            </a>
                                        </span>
                                    </div>
                                    <div className="px-3">
                                        <Button
                                            variant="secondary"
                                            onClick={(e: React.MouseEvent<HTMLFormElement, MouseEvent>) => handleSubmit(e)}
                                            disabled={!isValid || loading}>
                                            {loading ? (
                                                <>
                                                    <Spinner
                                                        animation="border"
                                                        role="status"
                                                        as="span"
                                                        size="sm"
                                                    />
                                                    Send Message
                                                </>
                                            ) : (
                                                'Send Message'
                                            )}
                                        </Button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                    <span
                        className={
                            'text-danger text-center mb-1 mt-4 font-feature'
                        }
                        style={{
                            display: failedAttempts ? 'block' : 'none',
                        }}>
                        <Fade collapse top when={failedAttempts > 0}>
                            {errorSpace}
                        </Fade>
                    </span>
                    <span
                        className={
                            'text-success text-center mb-1 mt-4 font-feature'
                        }
                        style={{
                            display: successAttempts ? 'block' : 'none',
                        }}>
                        <Fade collapse top when={successAttempts > 0}>
                            {successSpace}
                        </Fade>
                    </span>
                </div>
            </Card>
        </>
    );
};

export default EnquireNowCard;
