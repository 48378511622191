import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Spinner } from 'react-bootstrap';
import { IoMdInformationCircle } from 'react-icons/all';
import CustomModal from 'components/modals/Modal';
import LabelledBoldTextBlock from 'components/elements/labels/LabelledBoldTextBlock';
import LabelledContent from 'components/elements/labels/LabelledContent';
import Cancel from 'components/elements/buttons/Cancel';
import { Formik, useFormikContext } from 'formik';
import ContributionSchema from './validation/ContributionSchema';
import { useAsyncFn } from 'react-use';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG } from 'libs/constants';
import { getAccessToken, getMembershipId } from 'libs/auth';
import Fade from 'react-reveal/Fade';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import { setPaymentMethodModalVisible } from 'store';
import Bugsnag from '@bugsnag/js';
import { thunkGetMembership } from 'store';
import {
    calculateCoverDateAsync,
    calculatePaymentAmountAsync,
} from './functions/exports';
import { TabItem, PaymentAmount, CoverMeUntil } from './components/exports';
import { ymdToShortDate } from 'libs/helpers';
import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';
import gtagEvent from 'libs/gtagEvent';
import { getPaymentObj } from 'libs/premiumHelpers';

// this is a janky hack mate, but i'll fix it later
const CleanFormAfter = () => {
    const { resetForm } = useFormikContext();
    const membership = useSelector((state) => state.membership);

    useEffect(() => {
        if (membership) {
            console.log('RESET FORM');
            resetForm();
        }
    }, [membership]);

    return null;
};

const MakeAContributionModal = ({ show, handleClose, align }) => {
    const [selectedTab, setSelectedTab] = useState(1);
    const [loading, setLoading] = useState(false);

    const [premiumAmount, setPremiumAmount] = useState('--');
    const [premiumDate, setPremiumDate] = useState('--');
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [cardholder, setCardholder] = useState('');

    const [iframeOutcome, setIframeOutcome] = useState(null);
    const [iframeIsValid, setIframeIsValid] = useState(false);
    const [message, setMessage] = useState('');

    const membership = useSelector((state) => state.membership);
    const premium = useSelector((state) => state.premium);
    const debitAccounts = useSelector((state) => state.paymentMethods?.debitAccounts);
    const account = debitAccounts?.[0];
    const membershipId = membership?.MembershipId;

    const dispatch = useDispatch();

    const payment = getPaymentObj(premium, membership?.Contribution?.Frequency);

    const defaultValues = {
        contributionamount: '',
        contributiondate: '',
        cardholder:
            account?.chargeCard
                ?.cardholderName || '',
        understand: false,
        declare: false,
    };

    useEffect(() => {
        if (premiumAmount !== 'loading') return;
        setPremiumAmount(
            <ReactPlaceholder
                showLoadingAnimation
                rows={1}
                color="#e5e4ed"
                className="d-inline-block"
            />,
        );
    }, [premiumAmount]);

    useEffect(() => {
        if (premiumDate !== 'loading') return;
        setPremiumDate(
            <ReactPlaceholder
                showLoadingAnimation
                rows={1}
                color="#e5e4ed"
                className="d-inline-block"
            />,
        );
    }, [premiumDate]);

    let abortController = new AbortController();
    const [checkPremiumState, checkPremium] = useAsyncFn(
        async (value, fieldname) => {
            setIframeOutcome(null);
            setPremiumDate('loading');
            setPremiumAmount('loading');
            setPaymentAmount(0); // destroy iframe.
            abortController.abort(); // Cancel the previous request
            abortController = new AbortController();
            let CoverConfig;
            if (fieldname === 'contributionamount') {
                const data = { amount: value };
                CoverConfig = await calculateCoverDateAsync(
                    data,
                    abortController.signal,
                );
                if (process.env.REACT_APP_API_ENVIRONMENT === 'dev') {
                    console.log('COVER::amount', { CoverConfig });
                }
                setPremiumAmount(CoverConfig.premiumAmount);
                setPaymentAmount(CoverConfig.paymentAmount);
            }
            if (fieldname === 'contributiondate') {
                const data = { date: value };
                CoverConfig = await calculatePaymentAmountAsync(
                    data,
                    abortController.signal,
                );
                if (process.env.REACT_APP_API_ENVIRONMENT === 'dev') {
                    console.log('COVER::until', { CoverConfig });
                }
                setPremiumDate(CoverConfig.premiumDate);
                setPaymentAmount(CoverConfig.paymentAmount);
            }
        },
    );

    // function resetIframe() {
    //     const membershipId = membership?.MembershipId;
    //     const iframeHambs =
    //         document.getElementById('iframe_hambs_pay').contentWindow;
    //     iframeHambs.location.href = `${process.env.REACT_APP_API_URL}/nab/pay/${membershipId}/${paymentAmount}`;
    // }

    const url = api.publicHelpers.publicGetFullApiUrl(
        'uh',
        process.env.REACT_APP_API_ENVIRONMENT
    );

    const iframeComms = async (event) => {
        if (event.origin !== `${url}`) return;

        Bugsnag.leaveBreadcrumb(event.data);
        Bugsnag.leaveBreadcrumb(JSON.stringify(event.data));
        Bugsnag.leaveBreadcrumb('MembershipId: ' + membership?.MembershipId);

        const iframe = JSON.parse(event.data);

        if (iframe.number === 1) {
            // there is a form error
            if (process.env.REACT_APP_API_ENVIRONMENT === 'dev') {
                console.log('IFRAME::dirty', { iframe });
            }
        }

        if (iframe.valid) {
            if (process.env.REACT_APP_API_ENVIRONMENT === 'dev') {
                console.log('IFRAME::valid', { iframe });
            }
            setIframeIsValid(true);
        }

        if (iframe.summarycode === '1') {
            //a successful payment was made
            if (process.env.REACT_APP_API_ENVIRONMENT === 'dev') {
                console.log('IFRAME::success', { iframe });
            }

            // moved response message above request as we don't care about the response from the API re: HAMBS
            setLoading(false);

            setPremiumAmount('--');
            setPremiumDate('--');
            setIframeIsValid(false);

            //setPayment needs to be below payload
            setPaymentAmount(0);
            // the code below sends the successful payment response to the API
            // to forward to HAMBS, but the front end doesn't care about the result.
            // this looks ugly but address the timeout loop issue in TUHPORTAL-35

            try {
                /* Sometimes HAMBS takes forever to respond, reassure the end user.
                 *  If HAMBS takes even longer than that, just tell the end user
                 *  that the payment was received by NAB and be done with it.
                 */
                // const waitOnHAMBS = setTimeout(() => {
                //     setMessage(
                //         'This is taking longer than usual, Please be patient and do not close this window.',
                //     );
                // }, 10000);

                const access_token = getAccessToken();
                const memberid = getMembershipId();
                const payload = {
                    amount: paymentAmount,
                    cardholder: cardholder,
                    ref: iframe.txnid,
                };
                if (process.env.REACT_APP_API_ENVIRONMENT === 'dev') {
                    console.log('IFRAME::payload', { payload });
                }
                const response = await api
                    .user(CONFIG, access_token)
                    .membership.premium.premiumPay(memberid, payload);
                //cancel HAMBS taking longer than usual reassurance message if response was snappy.
                // clearTimeout(waitOnHAMBS);

                if (response?.status === 'error') {
                    Bugsnag.notify(response.message);
                    throw new Error(response.message);
                } else {
                    dispatch(thunkGetMembership());
                }
                setIframeOutcome('success');
                setMessage('Payment was successful');
                setTimeout(function () {
                    setMessage('');
                    setIframeOutcome(null);
                }, 30000);

                console.log(response);
            } catch (error) {
                console.log({ checkPremiumState });
                Bugsnag.notify(error);
                console.log(
                    'ERROR :: Payment made to NAB, not lodged with HAMBS',
                );
                if (process.env.REACT_APP_API_ENVIRONMENT === 'dev') {
                    console.log('IFRAME::fail', { iframe });
                }
                console.log({ error }, error?.message);
                setPaymentAmount(0);
                setMessage('Payment made to NAB, not lodged with HAMBS');
                setIframeOutcome('fail');
                // setLoading(false);
                // setTimeout(function () {
                //     setIframeOutcome(null);
                //     setMessage('');
                // }, 30000);
                // setIframeIsValid(false);

                // resetIframe();
            }

        }

        if (iframe.summarycode === '2') {
            // card was declined
            if (process.env.REACT_APP_API_ENVIRONMENT === 'dev') {
                console.log('IFRAME::declined', { iframe });
            }
            setLoading(false);
            setPaymentAmount(0);
            setPremiumAmount('--');
            setPremiumDate('--');
            setIframeOutcome('fail');
            setMessage(
                'Payment unsuccessful, Please check card details and balance then try again.',
            );
            setTimeout(function () {
                setIframeOutcome(null);
                setMessage('');
            }, 30000);
            setIframeIsValid(false);
            // resetIframe();
        }

        /**
         * Yup behaves weirdly with the iframe, when the iframe validates its self
         * it detects the value of cardholder to be undefined even though formik
         * recognizes the value. this blur event triggers a form touch to quickly
         * reacquire the cardholder value and prevents the ghost error from showing
         * on the form
         */
        document
            .getElementById('checkbox-declare')
            .dispatchEvent(new Event('blur', { bubbles: true }));
        return;
    };

    useEffect(() => {
        if (show) {
            window.removeEventListener('message', iframeComms);
            window.addEventListener('message', iframeComms, false);
            return () => {
                window.removeEventListener('message', iframeComms);
            };
        }
    }, [cardholder, paymentAmount, show]);

    const onSubmit = (values) => {
        gtagEvent({
            screen: 'contribution',
            action: 'submit',
            label: 'Submitted a payment from pay now modal',
            type: 'modal',
        });
        setLoading(true);
        setMessage(
            'We are processing your payment, Please do not close this window.',
        );
        setIframeOutcome('processing');
        if (values.cardholder) {
            setCardholder(values.cardholder);
        }
        document
            .getElementById('iframe_hambs_pay')
            .contentWindow.postMessage('submitcc', '*');
        if (process.env.REACT_APP_API_ENVIRONMENT === 'dev') {
            console.log('IFRAME::submitted');
        }
    };

    return (
        <CustomModal
            handleClose={() => {
                if (!loading) {
                    handleClose();
                    setSelectedTab(1);
                    setIframeOutcome(null);
                    setMessage('');

                    setPremiumDate('--');
                    setPaymentAmount(0);
                    setPremiumAmount('--');
                }
            }}
            align={align}
            show={show}
            title={'Make A Contribution'}>
            <div className={'text-primary row row-cols-1'}>
                <div
                    className={
                        'd-flex justify-content-between align-items-center col'
                    }>
                    <LabelledContent label="Next Payment">
                        <div className="font-feature text-black">
                            <strong>
                                {(!payment?.NetAmount
                                    ? ''
                                    : '$' + payment?.NetAmount) || '-'}
                            </strong>
                            /
                            <span
                                className="text-light-gray text-uppercase font-weight-medium"
                                style={{ fontSize: '11px' }}>
                                {membership?.Contribution?.Frequency || '-'}
                            </span>
                        </div>
                    </LabelledContent>
                    <LabelledBoldTextBlock
                        label="Due Date"
                        text={
                            ymdToShortDate(membership?.Contribution?.DueDate) ||
                            '-'
                        }
                    />
                    <LabelledBoldTextBlock
                        label="Next Payment"
                        text={
                            ymdToShortDate(
                                membership?.Contribution?.DebitOnDate,
                            ) || '-'
                        }
                    />
                </div>
                <Formik
                    enableReinitialize={true}
                    validateOnChange={true}
                    validateOnBlur={true}
                    validateOnMount={true}
                    initialValues={defaultValues}
                    validationSchema={ContributionSchema}
                    onSubmit={onSubmit}>
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            isValid,
                            getFieldProps,
                            setFieldValue,
                            setErrors,
                            handleSubmit,
                        } = props;
                        return (
                            <Form>
                                <div
                                    className={
                                        'd-flex align-items-center bg-body-gray'
                                    }
                                    style={{
                                        paddingTop: '2px',
                                        marginTop: '20px',
                                    }}>
                                    <div
                                        onClick={() => {
                                            if (!loading) {
                                                setSelectedTab(1);
                                                setFieldValue(
                                                    'contributiondate',
                                                    '',
                                                );
                                                setPremiumDate('--');
                                                setPaymentAmount(0);
                                                setIframeOutcome(null);
                                                setMessage('');
                                                gtagEvent({
                                                    screen: 'contribution',
                                                    action: 'payamount_tab',
                                                    label: 'Navigated to pay now modal pay amount tab',
                                                    type: 'modal',
                                                });
                                            }
                                        }}>
                                        <TabItem
                                            title="Payment Amount:"
                                            active={selectedTab === 1}
                                        />
                                    </div>
                                    <div
                                        onClick={() => {
                                            if (!loading) {
                                                setSelectedTab(0);
                                                setFieldValue(
                                                    'contributionamount',
                                                    '',
                                                );
                                                setPremiumAmount('--');
                                                setPaymentAmount(0);
                                                setIframeOutcome(null);
                                                setMessage('');
                                                gtagEvent({
                                                    screen: 'contribution',
                                                    action: 'coveruntil_tab',
                                                    label: 'Navigated to pay now modal cover until tab',
                                                    type: 'modal',
                                                });
                                            }
                                        }}>
                                        <TabItem
                                            title="Cover me until:"
                                            active={selectedTab === 0}
                                        />
                                    </div>
                                </div>
                                <PaymentAmount
                                    isVisible={selectedTab === 1}
                                    getFieldProps={getFieldProps}
                                    setFieldValue={setFieldValue}
                                    setErrors={setErrors}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    onSubmitState={isSubmitting}
                                    loading={loading}
                                    checkPremium={checkPremium}
                                    premiumAmount={premiumAmount}
                                />
                                <CoverMeUntil
                                    isVisible={selectedTab === 0}
                                    getFieldProps={getFieldProps}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    onSubmitState={isSubmitting}
                                    loading={loading}
                                    checkPremium={checkPremium}
                                    premiumDate={premiumDate}
                                />
                                {iframeOutcome && (
                                    <div className="modal-iframe-message">
                                        <Fade collapse top when={iframeOutcome}>
                                            <StatusNotificationCard
                                                status={
                                                    iframeOutcome === 'fail'
                                                        ? 'error'
                                                        : 'success'
                                                }>
                                                {message}
                                            </StatusNotificationCard>
                                        </Fade>
                                    </div>
                                )}
                                <div
                                    className="py-3"
                                    style={{
                                        boxShadow:
                                            'inset 0px -2px 0px 0px #E6E6EE',
                                    }}>
                                    <div
                                        className="text-black font-weight-medium px-3"
                                        style={{
                                            fontSize: '14px',
                                            marginBottom: '10px',
                                        }}>
                                        Payment Method
                                    </div>
                                    <div
                                        style={{
                                            display:
                                                !loading &&
                                                paymentAmount !== '--' &&
                                                paymentAmount !== 0 &&
                                                iframeOutcome !== 'processing'
                                                    ? 'block'
                                                    : 'none',
                                        }}>
                                        <Form.Group
                                            controlId={`credit-holder`}
                                            className="col-6 px-3 text-label-gray">
                                            <Form.Label
                                                style={{ fontSize: '11px' }}>
                                                Name on Card
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                className={
                                                    errors.cardholder &&
                                                    touched.cardholder &&
                                                    !loading
                                                        ? 'is-invalid'
                                                        : ''
                                                }
                                                {...getFieldProps('cardholder')}
                                            />
                                            {touched.cardholder &&
                                            errors.cardholder &&
                                            !loading ? (
                                                <Form.Control.Feedback
                                                    type={'invalid'}>
                                                    {errors.cardholder}
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                        {paymentAmount && (
                                            <>
                                                <iframe
                                                    src={`${url}/nab/pay/${membershipId}?payment=${paymentAmount}`}
                                                    id="iframe_hambs_pay"
                                                    style={{
                                                        border: 0,
                                                        height: '260px',
                                                        visibility:
                                                            iframeOutcome !==
                                                            'processing'
                                                                ? 'visible'
                                                                : 'hidden',
                                                    }}
                                                    border="0"
                                                    width="260px"
                                                    height="320px"
                                                    className="col-12 px-2"></iframe>
                                            </>
                                        )}
                                    </div>
                                    <div
                                        className="d-flex px-3"
                                        style={{
                                            marginTop: '20px',
                                            marginBottom: '5px',
                                        }}>
                                        <IoMdInformationCircle size={20} />
                                        <div
                                            className="text-black"
                                            style={{
                                                fontSize: '12px',
                                                marginLeft: '5px',
                                            }}>
                                            To better ensure that you&apos;re
                                            always covered,{' '}
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleClose();
                                                    setSelectedTab(1);
                                                    setIframeOutcome(null);
                                                    setMessage('');

                                                    setPremiumDate('--');
                                                    setPaymentAmount(0);
                                                    setPremiumAmount('--');

                                                    gtagEvent({
                                                        screen: 'contribution',
                                                        action: 'payment_method',
                                                        label: 'Clicked on pay now modal direct debit prompt',
                                                        type: 'modal_open',
                                                    });

                                                    dispatch(
                                                        setPaymentMethodModalVisible(
                                                            true,
                                                        ),
                                                    );
                                                }}>
                                                set up direct debit payments
                                            </a>{' '}
                                            instead.
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="p-3 text-black"
                                    style={{
                                        fontSize: '14px',
                                    }}>
                                    <Form.Check
                                        type="checkbox"
                                        id={`checkbox-understand`}
                                        label={`I understand that this payment is for TUH membership contributions only`}
                                        checked={values.understand}
                                        {...getFieldProps('understand')}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        id={`checkbox-declare`}
                                        label={`I declare that I am authorised to use the nominated account`}
                                        checked={values.declare}
                                        {...getFieldProps('declare')}
                                    />
                                </div>
                                <div className="p-3">
                                    <Button
                                        variant="secondary"
                                        style={{
                                            padding: '9px',
                                            marginRight: '10px',
                                        }}
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                        disabled={
                                            loading ||
                                            !iframeIsValid ||
                                            !isValid
                                        }>
                                        {loading ? (
                                            <>
                                                <Spinner
                                                    animation="border"
                                                    role="status"
                                                    as="span"
                                                    size="sm"
                                                />
                                                Submit Payment
                                            </>
                                        ) : (
                                            'Submit Payment'
                                        )}
                                    </Button>
                                    <Cancel
                                        style={{
                                            color: '#9998A8',
                                        }}
                                        onClick={() => {
                                            if (!loading) {
                                                gtagEvent({
                                                    screen: 'contribution',
                                                    action: 'cancel',
                                                    label: 'Closed or cancelled modal',
                                                    type: 'modal',
                                                });

                                                handleClose();
                                                setSelectedTab(1);
                                                setIframeOutcome(null);
                                                setMessage('');

                                                setPremiumDate('--');
                                                setPaymentAmount(0);
                                                setPremiumAmount('--');
                                            }
                                        }}
                                    />
                                </div>
                                <CleanFormAfter />
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CustomModal>
    );
};

export default MakeAContributionModal;
