import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useAsyncFn } from 'react-use';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { CONFIG } from 'libs/constants';
import { Link } from 'react-router-dom';
import api from '@qldtuh/tuh-uh-js-api';
import Fade from 'react-reveal/Fade';
import gtagEvent from 'libs/gtagEvent';
import ReactMarkdown from 'react-markdown';
import LogoPrimaryPositive from 'img/vector/LogoPrimaryPositive/comp';
import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';
import registrationSchema from './validation/registrationSchema';
import {
    DownloadButton,
    FeatureImage,
    FeatureTitle,
    Terms,
} from '../common/components/exports';
import {
    RegisterConfirmPassword,
    RegisterDob,
    RegisterEmail,
    RegisterName,
    RegisterNumber,
    RegisterPassword,
    SubmitRegistration,
} from './components/exports';

const AuthenticationScreen: React.FC = () => {
    const history = useHistory();
    const phone = '1300 661 283';

    const [featureImage, setFeatureImage] = useState<JSX.Element>();
    const [featureTitle, setFeatureTitle] = useState<JSX.Element>();
    const [androidButton, setAndroidButton] = useState<JSX.Element>();
    const [iosButton, setIosButton] = useState<JSX.Element>();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState<JSX.Element | string>();
    const [outcome, setOutcome] = useState<string | null>(null);

    /** KEEP THESE FOR TESTING */
    // const [outcome, setOutcome] = useState('fail');
    // const [message, setMessage] = useState(
    //     `Sorry, registration could not complete. Please try again, or contact Union Health on ${phone}`,
    // );

    const [loadFeatureState, loadFeature] = useAsyncFn(async () => {
        const {
            portal: { forgot: response },
        } = await api.app(CONFIG).settings();

        setFeatureTitle(
            <FeatureTitle
                text={<ReactMarkdown>{response.text}</ReactMarkdown>}
            />,
        );

        setFeatureImage(<FeatureImage src={response.bg_img} />);

        setIosButton(
            <DownloadButton
                image={response.app_store_img}
                link={response.app_store_url}
                alt={'iOS App Store download button'}
                gtag={() => {
                    gtagEvent({
                        screen: 'register',
                        action: 'download_ios',
                        label: 'Download from AppStore',
                    });
                }}
            />,
        );

        setAndroidButton(
            <DownloadButton
                image={response.play_store_img}
                link={response.play_store_url}
                alt={'Google Play Store download button'}
                gtag={() => {
                    gtagEvent({
                        screen: 'register',
                        action: 'download_android',
                        label: 'Download from Google Play Store',
                    });
                }}
            />,
        );
    });

    useEffect(() => {
        console.log('useAsyncFn is probably being used incorrectly: ', {
            loadFeatureState,
        });
        loadFeature();
    }, []);

    const initialValues = {
        memberId: '',
        firstName: '',
        lastName: '',
        email: '',
        dob: '',
        password: '',
        passwordConfirmation: '',
    };

    const onSubmit = (values) => {
        const {
            dob,
            email,
            firstName,
            lastName,
            memberId,
            password,
            passwordConfirmation,
        } = values;
        setLoading(true);
        gtagEvent({
            screen: 'register',
            action: 'register',
            label: 'Download from Google Play Store',
        });
        let data = {
            memberid: memberId,
            firstname: firstName,
            lastname: lastName,
            email: email,
            dob: dob,
            password: password,
            confirmpassword: passwordConfirmation,
        };

        api.user(CONFIG)
            .register(data)
            .then((response) => {
                setLoading(false);
                console.log(response);
                if (response.status === 'error') {
                    switch (response?.note) {
                        case 'Adding password failed, most likely already a registed account':
                            throw new Error('account exists already');
                        case 'DOB doesnt match':
                        case 'Email doesnt match':
                        case 'First name doesnt match':
                        case 'Last name doesnt match':
                        case undefined:
                        default:
                            throw new Error(response.message);
                    }
                }
                setOutcome('success');
                setMessage(
                    'After 2 minutes, you can go back to the login screen and login.',
                );
                setTimeout(() => {
                    setOutcome(null);
                    setMessage('');
                }, 30000);
            })
            .catch((error) => {
                const defaultMsg = `Sorry, registration could not complete. Please try again, or contact Union Health 
on ${phone}`;
                const alreadyHasAccount = (
                    <>
                        It looks like you may already have an account. If you
                        have forgotten your password you can recover it.{' '}
                        <Link
                            to="/forgot"
                            className="alert-link"
                            style={{ display: 'inline' }}
                            onClick={() => {
                                gtagEvent({
                                    screen: 'register',
                                    action: 'forgot_password',
                                    label: 'Download from Google Play Store',
                                    type: 'alert',
                                });
                            }}>
                            Click here for password recovery
                        </Link>
                        .
                    </>
                );
                setOutcome('fail');
                console.log({ error });

                switch (error.message) {
                    case 'This membershipPassword has been changed by another user or process.':
                        setMessage(alreadyHasAccount);
                        break;
                    case 'account exists already':
                        setMessage(alreadyHasAccount);
                        break;
                    default:
                        setMessage(defaultMsg);
                }

                setTimeout(() => {
                    setOutcome(null);
                    setMessage('');
                }, 30000);
            });
    };

    return (
        <main className="h-100 d-flex register-page">
            <div
                className="d-none d-lg-flex flex-column h-100 col-xl-7"
                style={{
                    backgroundColor: '#B1AEEE',
                    maxWidth: '1000px',
                }}>
                {featureImage}
                {featureTitle}
                <div
                    className={
                        'd-flex align-items-center justify-content-center'
                    }>
                    {iosButton}
                    {androidButton}
                </div>
            </div>
            <div className="bg-white col-lg-5 d-flex col-xl align-items-center justify-content-center">
                <Formik
                    validationSchema={registrationSchema}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validateOnBlur={false}
                    validateOnChange={true}
                    validateOnMount={true}>
                    {() => (
                        <>
                            <Form
                                className="container w-100 px-3"
                                autoComplete="new-field">
                                <div className="row">
                                    <input type="hidden" value="prayer" />
                                    <input
                                        type="email"
                                        name="email"
                                        style={{ display: 'none' }}
                                    />
                                    <input
                                        type="password"
                                        name="password"
                                        style={{ display: 'none' }}
                                        autoComplete='off'
                                    />

                                    <div className="col register-form--success">
                                        <div
                                            className={
                                                'w-100 align-items-center justify-content-center mb-2'
                                            }
                                            style={{
                                                display: 'flex',
                                            }}>
                                            <LogoPrimaryPositive />
                                        </div>
                                        <Fade collapse when={false}>
                                            <Button
                                                variant="secondary"
                                                block
                                                className={'p-2'}
                                                onClick={() => {
                                                    history.replace('/');
                                                }}>
                                                Ok
                                            </Button>
                                        </Fade>
                                    </div>

                                    <div
                                        className="row register-form--form"
                                        style={{
                                            display: 'flex',
                                        }}>
                                        <RegisterNumber />
                                        <RegisterName />
                                        <RegisterEmail />
                                        <RegisterDob />
                                        <RegisterPassword />
                                        <RegisterConfirmPassword />
                                        <SubmitRegistration
                                            outcome={outcome}
                                            loading={loading}
                                        />
                                        <div className="form-group col-12">
                                            <span
                                                className={`text-${
                                                    outcome === 'fail'
                                                        ? 'danger'
                                                        : 'success'
                                                } d-block text-center mb-1 mt-2 font-feature`}>
                                                <Fade
                                                    bottom
                                                    when={outcome !== null}>
                                                    <StatusNotificationCard
                                                        status={
                                                            outcome === 'fail'
                                                                ? 'error'
                                                                : 'success'
                                                        }>
                                                        {message}
                                                    </StatusNotificationCard>
                                                </Fade>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>
            </div>

            <div
                style={{
                    fontSize: 12,
                    position: 'absolute',
                    bottom: 20,
                    right: 20,
                }}
                className={'text-light-gray font-feature'}>
                <Terms />
            </div>
        </main>
    );
};

export default AuthenticationScreen;
