import LostMemberCard from 'components/elements/buttons/LostMemberCard';
import CardHorizontalRule from 'components/elements/divisions/CardHorizontalRule';
import LabelledLightTextBlock from 'components/elements/labels/LabelledLightTextBlock';
import MembershipDetailsModal from 'components/modals/MembershipDetailsModal/MembershipDetailsModal';
import gtagEvent from 'libs/gtagEvent';
import {
    getAddressByType,
    getPhoneByName,
    mainPerson,
    ymdToDate,
} from 'libs/helpers';
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

export const MembershipDetails = () => {
    const [membershipDetailsModalVisible, setMembershipDetailsModalVisible] =
        useState(false);
    const history = useHistory();

    const membership = useSelector((state) => state.membership);
    const product = useSelector((state) => state.product);

    const homeAddress = getAddressByType(membership, 'Home');
    let postAddress = getAddressByType(membership, 'Postal');

    const [dob, setDob] = useState('-');

    useEffect(() => {
        if (membership) {
            setDob(ymdToDate(mainPerson(membership)?.Person?.BirthDate) || '-');
        }
    }, [membership]);

    function doAddress(address) {
        if (!address || !address?.IsActive) return '-';

        return (
            <>
                {address?.Line1 ? <div>{address?.Line1},</div> : ''}
                {address?.Line2 ? <div>{address?.Line2},</div> : ''}
                {address?.Line3 ? <div>{address?.Line3},</div> : ''}
                {address?.Suburb ? <div>{address?.Suburb},</div> : ''}
                {address?.StateCode && address?.PostCode ? (
                    <div>
                        {address?.StateCode}, {address?.PostCode}
                    </div>
                ) : (
                    ''
                )}
                {address?.pending ? (
                    <div
                        className="text-uppercase text-primary font-weight-semibold"
                        style={{ fontSize: '10px' }}>
                        Pending update
                    </div>
                ) : (
                    ''
                )}
            </>
        );
    }

    function getPartnerAuthority(membership) {
        if (!membership?.CustomProperties?.CustomProperty) {
            return;
        }

        const customProperties = membership?.CustomProperties?.CustomProperty;

        if (typeof customProperties?.find === 'function') {
            const authority = customProperties.find(
                (property) => property.Key === 'Partner Access Authorised',
            );

            if (typeof authority != 'undefined') {
                switch (authority.Value) {
                    case 'Yes':
                        return 'Both me and my spouse';
                    case 'No':
                        return 'Only myself';
                    default:
                        break;
                }
            }
        }

        return 'Only myself';
    }

    return (
        <>
            <Card className="py-4">
                <h1 className="px-4 text-primary text-uppercase">
                    Membership Details
                </h1>

                <div className="my-3">
                    <CardHorizontalRule />
                </div>

                <div className="px-4">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-4">
                            <LabelledLightTextBlock
                                label="MEMBER NUMBER"
                                text={membership?.MembershipId}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mt-4 mt-sm-0">
                            <LabelledLightTextBlock
                                label="Date of Birth"
                                text={dob}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mt-4 mt-sm-0">
                            <LabelledLightTextBlock
                                label="Email Address"
                                text={
                                    membership?.EmailAddresses?.EmailAddress?.[0]?.EmailAddress || '-'
                                }
                            />
                        </div>
                    </div>

                    <div className="row mt-0 mt-sm-4 mt-4 mt-sm-0">
                        <div className="col-12 col-sm-6 col-lg-4">
                            <LabelledLightTextBlock
                                label="Home Phone"
                                text={getPhoneByName(membership, 'Home') || '-'}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mt-4 mt-sm-0">
                            <LabelledLightTextBlock
                                label="Mobile Phone"
                                text={
                                    getPhoneByName(membership, 'Mobile1') || '-'
                                }
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mt-4 mt-sm-0">
                            <LabelledLightTextBlock
                                label="Mobile Phone 2"
                                text={
                                    getPhoneByName(membership, 'Mobile2') || '-'
                                }
                            />
                        </div>
                    </div>

                    <div className="row mt-0 mt-sm-4 mt-4 mt-sm-0">
                        <div className="col-12 col-sm-6 col-lg-4 mt-4 mt-sm-0">
                            <LabelledLightTextBlock
                                label="Work Phone"
                                text={
                                    getPhoneByName(membership, 'Work1') || '-'
                                }
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                            <LabelledLightTextBlock
                                label="Home Address"
                                text={doAddress(homeAddress)}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mt-4 mt-sm-0">
                            <LabelledLightTextBlock
                                label="Postal Address"
                                text={doAddress(postAddress)}
                            />
                        </div>
                    </div>
                    <div className="row mt-0 mt-sm-4 mt-4 mt-sm-0">
                        <div
                            className="col-12 col-sm-6 col-lg-4 mt-4 mt-lg-0"
                            style={{
                                display: ['Cpl', 'Fam', 'FamX'].includes(
                                    product?.membershipClassCode,
                                )
                                    ? 'block'
                                    : 'none',
                            }}>
                            <LabelledLightTextBlock
                                label="Partner Authority"
                                text={getPartnerAuthority(membership) || '-'}
                            />
                        </div>
                    </div>

                    <div className="d-flex flex-wrap mt-4">
                        <Button
                            variant="primary-dark-1"
                            className="d-block d-sm-inline-block mr-3 mt-2 flex-shrink-1"
                            onClick={() => {
                                gtagEvent({
                                    screen: 'membership',
                                    action: 'membership_details',
                                    label: 'Clicked edit details button',
                                    type: 'modal_open',
                                });
                                setMembershipDetailsModalVisible(true);
                            }}>
                            Edit
                        </Button>
                        <Button
                            className="ml-0 mr-3 mt-2 flex-shrink-1"
                            variant="outline-gray"
                            onClick={() => {
                                gtagEvent({
                                    screen: 'membership',
                                    action: 'managecover',
                                    label: 'Clicked manage my cover button',
                                });
                                history.push('/member/cover');
                            }}>
                            Manage my cover
                        </Button>
                        <div className="mr-3 mt-2">
                            <LostMemberCard
                                gtag={() => {
                                    gtagEvent({
                                        screen: 'membership',
                                        action: 'newcard_request',
                                        label: 'Clicked on request new member card',
                                        type: 'modal_open',
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Card>

            <MembershipDetailsModal
                handleClose={() => {
                    gtagEvent({
                        screen: 'membershipdetails',
                        action: 'cancel',
                        label: 'Cancelled or closed modal',
                        type: 'modal',
                    });
                    setMembershipDetailsModalVisible(false);
                }}
                show={membershipDetailsModalVisible}
                align={'right'}
            />
        </>
    );
};
