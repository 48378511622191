import { User } from './actions-types';

const initialState = {};

export default (state = initialState, action) => {
    // console.log(action)

    switch (action.type) {
        case User.SET_USER:
            return {
                ...state,
                memberId: action.payload.membershipid,
                accessToken: action.payload.access_token,
            };
        case User.CLEAR_USER:
            return {};
        case User.SET_REFER_CODE:
            return {
                ...state,
                refer_code: action.payload,
            };
        default:
            return state;
    }
};
