import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { IoIosList, IoMdCard, IoMdCheckmark } from 'react-icons/all';
import { thunkUpdatePreferences } from 'store';
import gtagEvent from 'libs/gtagEvent';

export const StartNewClaimPage = () => {
    const prefs = useSelector((state) => state.userSettings);
    const history = useHistory();
    const dispatch = useDispatch();
    const [skipPage, setSkipPage] = useState(false);
    const [userSkipCheck, setUserSkipCheck] = useState(false);

    useEffect(() => {
        if (prefs?.skipPortalClaimStart) {
            setSkipPage(prefs?.skipPortalClaimStart);
            history.push('/member/claim/new');
        }
    }, [prefs]);

    const handleNextStep = () => {
        if (userSkipCheck) {
            dispatch(
                thunkUpdatePreferences({ skipPortalClaimStart: userSkipCheck }),
            );
            gtagEvent({
                screen: 'newclaim',
                action: 'skip_preference',
                label: 'chose to skip start new claim page in the future',
            });
        }
        gtagEvent({
            screen: 'newclaim',
            action: 'startnow',
            label: 'started new claim',
        });
        history.push('/member/claim/new');
    };

    if (skipPage) {
        return <Redirect to="/member/claim/new" />;
    } else {
        return (
            <>
                <div className="text-center start-claim-page">
                    <h1
                        className="font-weight-ultra pt-5"
                        style={{
                            fontSize: '38px',
                        }}>
                        Start a new Claim
                    </h1>

                    <div className="container py-5 steps-holders">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-3 step">
                                <div className="icon-holder">
                                    <IoIosList size={60} />
                                </div>
                                <div className="title-holder">
                                    1. Complete your claim details
                                </div>
                                <div className="description-holder"></div>
                            </div>
                            <div className="col-12 col-md-3 offset-md-1 step">
                                <div className="icon-holder">
                                    <IoMdCard size={60} />
                                </div>
                                <div className="title-holder">
                                    2. Check your payment details
                                </div>
                                <div className="description-holder"></div>
                            </div>
                            <div className="col-12 col-md-3 offset-md-1 step">
                                <div className="icon-holder">
                                    <IoMdCheckmark size={60} />
                                </div>
                                <div className="title-holder">
                                    3. Submit your claim
                                </div>
                                <div className="description-holder"></div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-5">
                        <Button
                            variant="secondary"
                            style={{
                                padding: '13.5px 65px',
                            }}
                            className=""
                            onClick={handleNextStep}>
                            Start now
                        </Button>
                    </div>
                    <div className="pt-3 pb-5">
                        <Form>
                            <Form.Check
                                type="checkbox"
                                id={`checkbox-skip`}
                                label={`Skip this screen`}
                                className={'text-label-gray'}
                                style={{
                                    fontSize: '14px',
                                }}
                                name={`skip-page`}
                                checked={userSkipCheck}
                                onChange={(val) => {
                                    setUserSkipCheck(val.target.checked);
                                }}
                            />
                        </Form>
                    </div>
                </div>
            </>
        );
    }
};
