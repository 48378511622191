import React, { useState, useEffect } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from 'components/modals/Modal';
import Cancel from 'components/elements/buttons/Cancel';
import { Formik } from 'formik';
import { useAsyncFn } from 'react-use';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG } from 'libs/constants';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { setMembership } from 'store/membership/actions';
import Fade from 'react-reveal/Fade';
import gtagEvent from 'libs/gtagEvent';
import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';
import { generateDefaults } from './helpers';
import { ClaimPaymentsModalSchema } from './validation/schema';
import { thunkGetDebitAccount } from 'store';

const ClaimPaymentsModal = ({ show, handleClose, align }) => {
    const dispatch = useDispatch();
    const membership = useSelector((state) => state.membership);
    const defaultValues = generateDefaults(membership);
    
    const [successSpace, setSuccessSpace] = useState(<>&nbsp;</>);
    const [successAttempts, setSuccessAttempts] = useState(0);
    const [errorSpace, setErrorSpace] = useState(<>&nbsp;</>);
    const [failedAttempts, setFailedAttempts] = useState(0);

    const [onSubmitState, onSubmit] = useAsyncFn(async (values) => {
        console.log(values);
        gtagEvent({
            screen: 'claimpayments',
            action: 'submit',
            label: 'Updated or added a credit account',
            type: 'modal',
        });

        let outValues = {};
        outValues.accounts = {};
        outValues.accounts.benefit = {};
        outValues.accounts.benefit.accountname = values.accountName;
        outValues.accounts.benefit.bsb = values.bsb;
        outValues.accounts.benefit.accountno = values.accountNumber;
        // Implement logic update Contribution Payments data if the checkbox is set
        if (values.sameBankAccount) {
            outValues.accounts.debit = {};
            outValues.accounts.debit.type = 'bank';
            outValues.accounts.debit.accountname = values.accountName;
            outValues.accounts.debit.bsb = values.bsb;
            outValues.accounts.debit.accountno = values.accountNumber;
        }

        const access_token = getAccessToken();
        const memberid = getMembershipId();

        console.log(outValues);
        try {
            const response = await api
                .user(CONFIG, access_token)
                .membership.updateMembership(memberid, outValues);

            if (response?.status === 'error') {
                throw new Error(response.message);
            } else {
                response?.message
                    ? setSuccessSpace(response.message)
                    : setSuccessSpace(
                        'Payment method updated successfully.',
                    );
                setSuccessAttempts(1);
                setTimeout(() => {
                    setSuccessSpace(<>&nbsp;</>);
                    setSuccessAttempts(0);
                }, 10000);

                dispatch(setMembership(response));
                dispatch(thunkGetDebitAccount());
            }
        } catch (err) {
            console.log('ERROR');
            console.log(err);
            setFailedAttempts(failedAttempts + 1);
            setErrorSpace(
                'There was an error updating your details, try again later.',
            );
        }
    });

    const [checkBSBStatus, checkBSB] = useAsyncFn(
        async (value, setFieldValue, form) => {
            if (value.match(/(?:\d{3})(?:-)?(?:\d{3})/)) {
                const access_token = getAccessToken();
                try {
                    const response = await api
                        .app(CONFIG, access_token)
                        .bsb(value);

                    if (response?.status === 'error') {
                        throw new Error(response);
                    } else {
                        console.log(response);
                        setFieldValue('bank', response.Bank);
                    }
                } catch (err) {
                    console.log({ checkBSBStatus });
                    console.log('ERROR');
                    console.log(err);
                    setFieldValue('bank', '');
                    form.validateForm();
                }
            }
        },
    );

    const handleBsbChange = async (getFieldProps, setFieldValue, form) => {
        try {
            checkBSB(getFieldProps('bsb').value, setFieldValue, form);
        } catch (error) {
            // Handle errors here, e.g., show an error message to the user
            console.error('Error handling BSB change:', error);
        }
    }


    return (
        <CustomModal
            handleClose={handleClose}
            align={align}
            show={show}
            title={'Claim Payments'}>
            <div className={'text-primary font-feature row row-cols-1'}>
                <Formik
                    validateOnChange={true}
                    validateOnBlur={true}
                    validateOnMount={true}
                    initialValues={defaultValues || null}
                    validationSchema={ClaimPaymentsModalSchema}
                    onSubmit={(values, { resetForm }) =>
                        onSubmit(values, resetForm)
                    }>
                    {(props) => {
                        const {
                            form,
                            touched,
                            errors,
                            dirty,
                            handleSubmit,
                            isValid,
                            getFieldProps,
                            setFieldValue,
                        } = props;

                        useEffect(() => {
                            handleBsbChange(getFieldProps, setFieldValue, form);
                        }, [setFieldValue]);

                        return (
                            <Form>
                                <div
                                    className="d-flex align-items-center justify-content-between pt-2 pb-3"
                                    style={{
                                        boxShadow:
                                            'inset 0px -2px 0px 0px #E6E6EE',
                                    }}>
                                    <div className="row pl-2 pr-2">
                                        <Form.Group
                                            controlId={`benefit-bank`}
                                            className="col-6 text-label-gray pr-1">
                                            <Form.Label style={{ fontSize: '11px' }}>
                                                Bank
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                className={
                                                    errors.bank && touched.bank && !onSubmitState.loading
                                                        ? ''
                                                        : ''
                                                }
                                                readOnly
                                                {...getFieldProps('bank')}
                                            />

                                        </Form.Group>
                                        <Form.Group
                                            controlId={`benefit-bsb`}
                                            className="col-3 text-label-gray pl-1 pr-0">
                                            <Form.Label style={{ fontSize: '11px' }}>
                                                BSB
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                className={
                                                    errors.bsb && touched.bsb && !onSubmitState.loading
                                                        ? 'is-invalid'
                                                        : ''
                                                }
                                                {...getFieldProps('bsb')}
                                                onChange={(val) => {
                                                    setFieldValue('bsb', val.target.value);
                                                    checkBSB(val.target.value, setFieldValue, form);
                                                }}
                                            />
                                            {touched.bsb && errors.bsb && !onSubmitState.loading ? (
                                                <Form.Control.Feedback type={'invalid'}>
                                                    {errors.bsb}
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group
                                            controlId={`benefit-account`}
                                            className="col-3 text-label-gray pl-0">
                                            <Form.Label
                                                style={{ fontSize: '11px' }}>
                                                Account Number
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                className={
                                                    errors.accountNumber &&
                                                        touched.accountNumber &&
                                                        !onSubmitState.loading
                                                        ? 'is-invalid'
                                                        : ''
                                                }
                                                {...getFieldProps(
                                                    'accountNumber',
                                                )}
                                            />
                                            {touched.accountNumber &&
                                                errors.accountNumber &&
                                                !onSubmitState.loading ? (
                                                <Form.Control.Feedback
                                                    type={'invalid'}>
                                                    {errors.accountNumber}
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group
                                            controlId={`benefit-holder`}
                                            className="col-12 text-label-gray">
                                            <Form.Label
                                                style={{ fontSize: '11px' }}>
                                                Account Holder
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                className={
                                                    errors.accountName &&
                                                        touched.accountName &&
                                                        !onSubmitState.loading
                                                        ? 'is-invalid'
                                                        : ''
                                                }
                                                {...getFieldProps(
                                                    'accountName',
                                                )}
                                            />
                                            {touched.accountName &&
                                                errors.accountName &&
                                                !onSubmitState.loading ? (
                                                <Form.Control.Feedback
                                                    type={'invalid'}>
                                                    {errors.accountName}
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>

                                        {/* Benefit Account Details check box */}
                                        <div className="col-12 text-label-gray">
                                            <Form.Check
                                                type="checkbox"
                                                id={`checkbox-update-benefit-account-details`}
                                                label={`Update my contributions account details as well`}
                                                className={
                                                    errors.sameBankAccount &&
                                                    touched.sameBankAccount &&
                                                    !onSubmitState.loading
                                                        ? 'is-invalid text-label-gray font-weight-medium'
                                                        : 'text-label-gray font-weight-medium'
                                                }
                                                style={{
                                                    fontSize: '13px',
                                                }}
                                                {...getFieldProps(
                                                    'sameBankAccount',
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col pt-3">
                                    <Button
                                        variant="secondary"
                                        style={{
                                            padding: '9px',
                                            marginRight: '10px',
                                        }}
                                        onClick={handleSubmit}
                                        disabled={
                                            !isValid ||
                                            onSubmitState.loading ||
                                            !dirty
                                        }>
                                        {onSubmitState.loading ? (
                                            <>
                                                <Spinner
                                                    animation="border"
                                                    role="status"
                                                    as="span"
                                                    size="sm"
                                                />
                                                Save Changes
                                            </>
                                        ) : (
                                            'Save Changes'
                                        )}
                                    </Button>

                                    <Cancel
                                        style={{
                                            color: '#9998A8',
                                        }}
                                        onClick={handleClose}
                                    />
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
                <div style={{ paddingInline: '10px' }}>
                    <Fade collapse bottom when={failedAttempts > 0}>
                        <StatusNotificationCard status="error">
                            {errorSpace}
                        </StatusNotificationCard>
                    </Fade>
                </div>
                <div style={{ paddingInline: '10px' }}>
                    <Fade collapse bottom when={successAttempts > 0}>
                        <StatusNotificationCard status="success">
                            {successSpace}
                        </StatusNotificationCard>
                    </Fade>
                </div>
            </div>
        </CustomModal>
    );
};

export default ClaimPaymentsModal;
