import CustomModal from 'components/modals/Modal';
import React, { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import gtagEvent from 'libs/gtagEvent';
import { getSelectedUser, allPersons } from 'libs/helpers';
import { getAccessToken, getMembershipId } from 'libs/auth';
import {
    setEmailConfirmationModalVisible,
    setEmailConfirmed,
    addIdWithEmailConfirmed,
    setMembership,
} from 'store';
import {
    getUserEmailDetails,
    updateUserEmailDetails,
} from 'libs/confirmEmailHelpers';
import { ConfirmEmailModalSchema } from './validation/schema';

const ConfirmEmailModal = ({ align, handleConfirm }) => {
    const dispatch = useDispatch();

    const membership = useSelector((state) => state.membership);
    const confirmForUserId = useSelector(
        (state) => state.emailConfirmation.confirmFor,
    );
    const confirmEmailModalVisible = useSelector(
        (state) => state.ui.emailConfirmationModalVisible,
    );
    const USERS = allPersons(membership);
    const confirmUser = getSelectedUser(USERS, confirmForUserId);

    const [email, setEmail] = useState('');
    const [consent, setConsent] = useState(false);
    const [showConsent, setShowConsent] = useState(true);

    const { EmailAddress, IsConsentToEmail } = getUserEmailDetails(
        confirmUser,
        membership,
    );

    useEffect(() => {
        setEmail(EmailAddress ?? '');
        setConsent(IsConsentToEmail);
        setShowConsent(!IsConsentToEmail);
    }, [confirmUser, membership]);

    const [onSubmitState, onSubmit] = useAsyncFn(async (values) => {
        if (Object.keys(values.touched).length > 0 || values?.email !== EmailAddress) {
            delete values.touched;
            values.access_token = getAccessToken();
            values.memberid = getMembershipId();

            try {
                const response = await updateUserEmailDetails(values);
                console.log({ response });
                dispatch(setMembership(response));
            } catch (error) {
                console.log('ERROR', error);
            }
        }
        dispatch(setEmailConfirmed(true));
        dispatch(addIdWithEmailConfirmed(values?.confirmUser?.Person?.PersonId));
        dispatch(setEmailConfirmationModalVisible(false));
        values.handleConfirm();
    });

    return (
        <CustomModal
            handleClose={() => {
                gtagEvent({
                    screen: 'confirmemail',
                    action: 'cancel',
                    label: 'Cancelled or closed modal',
                    type: 'modal',
                });
                dispatch(setEmailConfirmationModalVisible(false));
            }}
            align={align}
            show={confirmEmailModalVisible}
            title={'Confirm Email Address'}>
            <Formik
                validateOnChange={true}
                validateOnBlur={true}
                validateOnMount={false}
                initialValues={{ email, consent }}
                enableReinitialize={true}
                validationSchema={ConfirmEmailModalSchema}
                onSubmit={(values) => {
                    onSubmit(values);
                }}>
                {(formik) => {
                    return (
                        <Form>
                            <p className="font-weight-medium py-2">
                                Take a moment to confirm your email address.
                                Here are your current settings.
                            </p>
                            <div className="col">
                                <Form.Group controlId={'email'}>
                                    <Form.Label className="text-label-gray">
                                        Email
                                    </Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        // Use formik values instead of email state
                                        value={formik.values.email} 
                                        className={
                                            formik.errors.email
                                                ? 'is-invalid'
                                                : ''
                                        }
                                        onChange={formik.handleChange}
                                    />
                                    {formik.errors.email ? (
                                        <Form.Control.Feedback type={'invalid'}>
                                            {formik.errors.email}
                                        </Form.Control.Feedback>
                                    ) : null}
                                </Form.Group>

                                <Form.Group controlId={'consent'}>
                                {showConsent &&
                                    <Form.Check
                                        type="checkbox"
                                        id={`checkbox-consent`}
                                        label={`I consent to TUH sending me communications via email`}
                                        checked={formik.values.consent}
                                        onChange={() => {
                                            formik.setFieldValue('consent', !formik.values.consent); // Update consent using Formik's setFieldValue
                                            formik.setFieldTouched('consent', true); // Mark consent field as touched
                                        }}
                                        style={{
                                            fontSize: '13px',
                                        }}
                                        name="consent"
                                    />
                                }
                                </Form.Group>
                                <div className="col pt-3 text-center">
                                    <Button
                                        variant="secondary"
                                        style={{
                                            padding: '9px',
                                            marginRight: '10px',
                                        }}
                                        onClick={() => {
                                            gtagEvent({
                                                screen: 'confirmemail',
                                                action: 'submit',
                                                label: 'Confirmed email whilst retrieving state',
                                                type: 'modal',
                                            });
                                            onSubmit({
                                                email: formik.values.email,
                                                consent: formik.values.consent,
                                                touched: formik.touched,
                                                confirmUser,
                                                handleConfirm,
                                            });
                                        }}
                                        disabled={
                                            onSubmitState.loading ||
                                            !formik.isValid
                                        }>
                                        {onSubmitState.loading ? (
                                            <>
                                                <Spinner
                                                    animation="border"
                                                    role="status"
                                                    as="span"
                                                    size="sm"
                                                />
                                                Confirm Email
                                            </>
                                        ) : (
                                            'Confirm Email'
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </CustomModal>
    );
};

export default ConfirmEmailModal;
