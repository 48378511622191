import React from 'react';
import { Form } from 'react-bootstrap';
import { injectClaimant } from '../common/functions';
import { FastClaimTypeItem } from 'types/fastClaim';
import { MembershipPersonType } from '@qldtuh/tuh-uh-js-api';

interface Props {
    persons: MembershipPersonType[];
    claimItem?: FastClaimTypeItem;
    updateClaim: (newObject: any) => void;
    handleChange: (event: React.ChangeEvent) => void;
}
export const Claimant: React.FC<Props> = ({ persons, claimItem, updateClaim, handleChange }) => {
    return (
        <Form.Group controlId={`claimant`} className="col-6 text-label-gray">
            <Form.Label style={{ fontSize: '11px' }}>Claimant</Form.Label>
            <Form.Control
                as="select"
                className={''}
                onChange={(event) => {
                    const newObject = injectClaimant(claimItem, event);
                    updateClaim(newObject);
                    handleChange(event);
                }}
                disabled={
                    claimItem?.dateOfService === undefined ||
                    claimItem.dateOfService === ''
                }
                value={claimItem?.personId ?? ''}>
                <option value="">- Please Select -</option>
                {typeof persons?.map === 'function' &&
                    persons.map((person, idx) => {
                        return (
                            <option key={idx} value={person.Person.PersonId}>
                                {person.Person.GivenName}{' '}
                                {person.Person.Surname}
                            </option>
                        );
                    })}
            </Form.Control>
        </Form.Group>
    );
};

export default Claimant;
